import { useContext, useMemo } from "react";
import { AuthContext } from "../contexts/auth.context";
import Cookies from "universal-cookie/cjs/Cookies";
import { ValidatedCardData } from "../types/ValidatedCardData";

//const cookie = new Cookies();

const useAuthContext = () => {
    const { validatedCard } = useContext(AuthContext);

    let userInfo = localStorage.getItem('userInfo');//cookie.get('userInfo') as ValidatedCardData;

    const instance = useMemo(() => {
        if (Object.keys(validatedCard).length > 0)
            return validatedCard;
        if (userInfo)
            return JSON.parse(userInfo) as ValidatedCardData;
        return {} as ValidatedCardData;

    }, [validatedCard]);

    return instance as ValidatedCardData;
}

export default useAuthContext;