import { NavLink } from "react-router-dom";

export default function Faq() {
  return (
    <section className="bg-faded-primary">
      <div className="container">
        <section className="container pt-1 pt-lg-3">
          <div className="position-relative overflow-hidden px-3 px-sm-4 px-md-0 py-5">


            <div className="row justify-content-center position-relative zindex-2 py-lg-4">
              <div className="col-xl-8 col-lg-9 col-md-10 py-2">
                <h2 className="h1 text-center mt-n2 mt-lg-0 mb-4 mb-lg-5">Frequently Asked Questions</h2>
                <div className="accordion" id="faq">

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3" type="button" data-bs-toggle="collapse" data-bs-target="#q-1" aria-expanded="true" aria-controls="q-1">I have been given a Gift to Dine Gift Card, how does it work?</button>
                    </h3>
                    <div className="accordion-collapse collapse show" id="q-1" data-bs-parent="#faq" >
                      <div className="accordion-body fs-sm pt-0">
                        <p>The Gift to Dine Gift Cards are a range of physical and digital gift cards that can be swapped for a participating Restaurant Digital Gift Card on <a href="https://gifttodine.com.au">www.gifttodine.com.au</a>, provided that the Gift to Dine Gift Card has enough unused value to make the swap.</p>
                        <p><strong>How to swap your Gift to Dine Gift Card for a Restaurant Digital Gift Card:</strong></p>
                        <ul className="list-unstyled">
                          <li className="mb-2"><strong>Step 1.</strong> Visit the Gift to Dine website <a href="https://gifttodine.com.au">www.gifttodine.com.au</a> enter your Gift to Dine Gift Card 16-digit card number and 4-digit PIN. For a physical Card this is located inside the carrier, for a digital Card this is located via the link in the email.</li>
                          <li className="mb-2"><strong>Step 2.</strong> Select your Restaurant Digital Gift Cards of choice from the list of participating restaurants on <a href="https://gifttodine.com.au">www.gifttodine.com.au</a></li>
                          <li className="mb-2"><strong>Step 3.</strong> Once you've checked out and confirmed your order, you'll receive an email with your Restaurant Digital Gift Card selections.</li>
                          <li className="mb-2"><strong>Step 4.</strong> Now you can redeem your Restaurant Digital Gift Cards at the selected restaurant/s.</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-2" aria-expanded="false" aria-controls="q-2">Where can the Gift to Dine Gift Cards be used?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-2" data-bs-parent="#faq" >
                      <div className="accordion-body fs-sm pt-0">
                        <p>The Gift to Dine Gift Cards can be swapped for a Restaurant Digital Gift Card from any of the participating restaurants listed on the Gift to Dine Website <a href="https://gifttodine.com.au/restaurants">www.gifttodine.com.au</a>. Once you have entered your Gift to Dine Gift Card number and PIN, you will be able to view the available Restaurant Digital Gift Cards that you can swap your Gift to Dine Gift Card for. The Gift to Dine Gift Cards cannot be used to withdraw cash at ATM or EFTPOS facilities.</p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-3" aria-expanded="false" aria-controls="q-3">Can I spend my Gift to Dine Gift Card(s) directly at the restaurants?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-3" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        <p>No, your Gift to Dine Gift Card must be swapped at <a href="https://gifttodine.com.au">www.gifttodine.com.au</a> for a Restaurant Digital Gift Card from any of the participating restaurants listed on the Gift to Dine Website.</p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-4" aria-expanded="false" aria-controls="q-4">Does my Gift to Dine Gift Card have an expiry date?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-4" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        <p>Yes, a Gift to Dine Gift Card will expire 36 months (3 years) from the date of purchase. Once your Gift to Dine Gift Card has expired, it cannot be used or reactivated in any way.</p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-5" aria-expanded="false" aria-controls="q-5">Does the Restaurant Digital Gift Card that I swapped my Gift to Dine Gift Card for have an expiry date?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-5" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        <p>Once a Restaurant Digital Gift Card has been received, that participating restaurant’s terms and conditions and expiry date will apply. Expiry dates may vary from product to product –please check each Restaurant Digital Gift Card’s terms and conditions, which will be presented to you via a link at the swap confirmation page on <a href="https://gifttodine.com.au">www.gifttodine.com.au</a></p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-6" aria-expanded="false" aria-controls="q-6">How can I check the Gift to Dine Gift Card’s available balance?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-6" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        <p>If you did not swap all the funds on your Gift to Dine Gift Card you can check your balance at <a href="https://gifttodine.com.au">www.gifttodine.com.au</a>. You’ll need to re-enter your card number and PIN via the ‘SWAP’ button in the main navigation. Your card number and PIN can found inside the carrier for the physical Gift to Dine Gift Card and via the link in the email for the digital Gift to Dine Gift Card. The resulting search page will show the available balance remaining and expiry date, as well as the most recent transaction summary or please contact us via the form at <NavLink to="/contactUs">www.gifttodine.com.au/contactus</NavLink>  and we will help check your card balance, expiry and transaction history. </p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-7" aria-expanded="false" aria-controls="q-7">Are there any fees for using the Gift to Dine Gift Card or checking the Gift Card balance or transaction history?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-7" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        <p>No, there are no fees associated with use of the Gift to Dine Gift Card or checking the Gift Card’s balance or transactions.</p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-8" aria-expanded="false" aria-controls="q-8">Where do I find the Pin for my Gift to Dine Gift Card?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-8" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        <p>Your 4-digit PIN can be found inside the Gift Card carrier for the physical Gift to Dine Gift Card or via the link in the email for the digital Gift to Dine Gift Card.</p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-9" aria-expanded="false" aria-controls="q-9">What do I do if my Gift to Dine Gift Card isn’t working?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-9" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        Complete the <NavLink to="/contactUs">Contact Us</NavLink> form and we will be in touch as soon as possible.
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-10" aria-expanded="false" aria-controls="q-10">How can I replace my Gift to Dine Gift Card if it is not working?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-10" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        <p>The only time the Card Issuer will replace a Gift to Dine Gift Card is when: the Gift to Dine Gift Card is valid (i.e. it has not expired); there is available balance remaining on the Gift to Dine Gift Card; and the Cardholder is in possession of the physical or digital Gift to Dine Gift Card. Contact Gift to Dine Gift Card at <NavLink to="/contactUs">Contact Us</NavLink> and ensure you have the 16-digit number and the 4-digit PIN present as you will be required to provide this information. This can be found inside of the carrier of the physical Gift to Dine Gift Card or via the link of the email for the digital Gift to Dine Gift Card.</p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-11" aria-expanded="false" aria-controls="q-11">How do I redeem more than one Gift to Dine Gift Card?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-11" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        <p>If you have more than one Gift to Dine Card, you will need to complete the redemption process separately for each card. Multiple cards cannot be redeemed in a single transaction.</p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-12" aria-expanded="false" aria-controls="q-12">Do I have to spend the full value of my Gift to Dine Gift Card?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-12" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        <p>No, you may spend all or a portion of your balance when you are redeeming your Gift to Dine Gift Card(s) for a Restaurant Digital Gift Card for a participating restaurant. If you choose to leave a balance, you can revisit the website at any time to redeem the remaining balance. <strong>Make sure to hold onto your Gift to Dine Gift Card so you can re-enter your card number and PIN when you’re ready to redeem the remainder of your balance for a Restaurant Digital Gift Card.</strong></p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-13" aria-expanded="false" aria-controls="q-13">Can I redeem my Gift to Dine Gift Card for more than one Restaurant Digital Gift Card?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-13" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        <p>Yes, you can split the value of your Gift to Dine Gift Cards across multiple participating Restaurant Digital Gift Cards.</p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                    <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                      <h3 className="accordion-header">
                        <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-14" aria-expanded="false" aria-controls="q-14">What can I exchange my Gift to Dine Gift Card for?</button>
                      </h3>
                      <div className="accordion-collapse collapse" id="q-14" data-bs-parent="#faq">
                        <div className="accordion-body fs-sm pt-0">
                          <p>Your Gift to Dine Gift Card can be exchanged at over 400+ premium restaurants Australia wide. Visit <a href="https://gifttodine.com.au">www.gifttodine.com.au</a> for a list of participating restaurants.</p>
                        </div>
                      </div>
                    </div>

                  {/* <!-- Item --> */}
                    <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                      <h3 className="accordion-header">
                        <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-15" aria-expanded="false" aria-controls="q-15">Why can’t I redeem my Gift to Dine Gift Card for Digital Gift Card for less than or more than the amounts shown?</button>
                      </h3>
                      <div className="accordion-collapse collapse" id="q-15" data-bs-parent="#faq">
                        <div className="accordion-body fs-sm pt-0">
                          <p>The minimum and maximum value for each Digital Gift Card is set by BLACKHAWK NETWORK according to program terms. You can buy multiple Digital Gift Cards for the same restaurant if you wish to buy more than a single card will allow.</p>
                        </div>
                      </div>
                    </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-16" aria-expanded="false" aria-controls="q-16">Can I spend my Restaurant Digital Gift Card on the Participating Restaurant website?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-16" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        <p>This depends on the participating Restaurants policy. Before you order your Restaurant Digital Gift Card please review the participating Restaurant terms and conditions, which will be presented to you via a link at the swap confirmation page on <a href="https://gifttodine.com.au">www.gifttodine.com.au</a></p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-17" aria-expanded="false" aria-controls="q-17">How long will my Restaurant Digital Gift Card(s) take to arrive?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-17" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        <p>Your Restaurants Digital Gift Card(s) will usually be delivered to you via email within minutes of processing your order. However, email delivery can take up to 1-2 business days. If the email is undelivered, please check your junk/spam folder before contacting support.</p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-18" aria-expanded="false" aria-controls="q-18">What do I do if I haven’t yet received my Restaurant Digital Gift Card(s)?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-18" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        <p>Firstly, please check your spam/junk folders for Restaurant Digital Gift Card. Otherwise, if your Restaurant Digital Gift Card(s) have not arrived within 48 hours, please <NavLink to="/contactUs">contact us</NavLink>.</p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-19" aria-expanded="false" aria-controls="q-18">I entered an incorrect email delivery email address during checkout – what do I do?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-19" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        <p>If the email address provided was incorrect, please complete the <NavLink to="/contactUs">contact us</NavLink></p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-20" aria-expanded="false" aria-controls="q-19">I have lost Restaurant Digital Gift Card, what do I do?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-20" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        <p>Restaurant Digital Gift Cards can be re-sent if you have lost or accidentally deleted them from your email (providing they are still valid), but we cannot replace Restaurant Digital Gift Cards that have been redeemed. Restaurant Digital Gift Cards should be treated as cash – we cannot refund any that are lost or stolen once you’ve received them.</p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-21" aria-expanded="false" aria-controls="q-20">Can I return my Restaurant Digital Gift Card or swap it for cash?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-21" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        <p>We’re unable to process exchange/returns of any kind, either for an alternative or cash.</p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-22" aria-expanded="false" aria-controls="q-22">Can Gift to Dine Gift Cards be reloaded?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-22" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        <p>No, these gift cards cannot be reloaded.</p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-23" aria-expanded="false" aria-controls="q-23">Can Gift to Dine Gift Cards be redeemed for cash?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-23" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        <p>No – they can only be redeemed for Restaurant Digital Gift Cards for participating restaurants.</p>
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm mb-3">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-24" aria-expanded="false" aria-controls="q-24">What happens if I lose the Gift to Dine Gift Card or it is stolen?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-24" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        Lost, stolen, damaged or expired Gift Cards cannot be replaced or refunded. Please refer to the terms and conditions <NavLink to="/tnc">here</NavLink>.
                      </div>
                    </div>
                  </div>

                  {/* <!-- Item --> */}
                  <div className="accordion-item border-0 rounded-3 shadow-sm ">
                    <h3 className="accordion-header">
                      <button className="accordion-button shadow-none rounded-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#q-25" aria-expanded="false" aria-controls="q-25">How do I contact you?</button>
                    </h3>
                    <div className="accordion-collapse collapse" id="q-25" data-bs-parent="#faq">
                      <div className="accordion-body fs-sm pt-0">
                        <p>Please complete the <NavLink to="/contactUs">Contact Us</NavLink> form and we will be in touch within 1-2 business days.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  )
}