const TermsAndConditions = () => {
  return (
    <>
      <section className="bg-faded-primary">
        <div className="container">
          <section className="container pt-1 pt-lg-3">
            <div className="position-relative overflow-hidden px-3 px-sm-4 px-md-0 py-5">


              <div className="row justify-content-center position-relative zindex-2 py-lg-4">
                <div className="col-xl-8 col-lg-9 col-md-10 py-2">
                  <h2 className="h1 text-center mt-n2 mt-lg-0 mb-4 mb-lg-5">Gift Card Terms &amp; Conditions</h2>
                  <div className="card border-0 rounded-3 shadow-sm p-4">
                    <p><strong>Terms and conditions:</strong> These terms and conditions apply to use of the Gift To Dine Gift Card issued by Blackhawk Network (Australia) Pty Ltd ABN 84 123 251 703 (<strong>“Blackhawk”</strong>) (<strong>“Terms and Conditions”</strong>). If you give this Gift To Dine Gift Card to another person, you must tell them that they will be bound by these Terms and Conditions. These Terms and Conditions will be governed by the laws of New South Wales. By using your Gift to Dine Gift Card, you unconditionally submit to the jurisdiction of the courts of NSW.</p>
                    <p>The Gift To Dine Gift Card may be used to swap for a Restaurant digital gift card for a participating restaurant (Restaurant <strong>Digital Gift Card(s)</strong>), provided that the Gift To Dine Gift Card has enough unused balance to make the swap. Once the Restaurant Digital Gift Card(s) has been received, the participating restaurant’s terms and conditions and expiry date will apply to the use of the Restaurant Digital Gift Card(s)</p>

                    <ol>
                      <li className="mb-2">These Terms and Conditions apply to your use of Gift To Dine Gift Card. Users of the Gift To Dine Gift Card are deemed to accept these Terms and Conditions of use.</li>
                      <li className="mb-2">The Gift To Dine Gift Card is not registered to any user. Consequently, anyone in possession of the Gift To Dine Gift Card may use it. Any use of the Gift To Dine Gift Card is deemed to be made by the cardholder. To the extent permitted by law, defaced, mutilated, altered, lost or stolen Gift To Dine Gift Cards will not be replaced unless such loss or damage is caused or contributed by our acts or omissions. If your Gift To Dine Gift Card is lost or stolen, or you suspect an unauthorised transaction, you agree to immediately notify us. We may be able to stop the Gift To Dine Gift Card value being used, but require the Gift To Dine Gift Card number, original balance and expiry date.</li>
                      <li className="mb-2">To use your Gift To Dine Gift Card, visit www.gifttodine.com.au and redeem for a participating Restaurant’s Digital Gift Card of your choice.  These Restaurant Digital Gift Cards are issued by participating restaurants and the participating restaurant’s terms and conditions and expiry date will apply to the Restaurant Digital Gift Card you select.  You must make yourself aware of any participating restaurant’s terms and conditions applicable to the Restaurant Digital Gift Cards. You acknowledge and agree that participating restaurants may change from time to time for reasons beyond our control or where required by law. We are not required to provide you notice if participating restaurants change.</li>
                      <li className="mb-2">To the extent permitted law, the Gift To Dine Gift Card cannot be redeemed for cash, reloaded, returned for a refund, have their balances consolidated to a new gift card, or be replaced after expiry and are not legal tender, account cards, credit or debit cards or securities. Cash will not be given for any unused amount. Gift To Dine Gift Cards cannot be used towards the purchase of any other cards or vouchers in participating restaurants.</li>
                      <li className="mb-2">For the avoidance of doubt, our goods and services comprise the stored value of the Gift To Dine Gift Card. We have no control over any feature or failure of goods and services that you purchase using the Gift To Dine Gift Card, including the Restaurant Digital Gift Card. Claims relating to the goods and services you purchase using the Restaurant Digital Gift Card must be directed towards the relevant participating restaurants where the transaction was made.</li>
                      <li className="mb-2">Our goods and/or services come with guarantees that cannot be excluded under the Australian Consumer Law. You are entitled to a replacement or refund for a major failure and compensation for any other reasonably foreseeable loss or damage. You are also entitled to have the goods repaired or replaced if the goods fail to be of acceptable quality and the failure does not amount to a major failure.</li>
                      <li className="mb-2">The Gift To Dine Gift Cards are valid until the expiry date shown on the Gift To Dine Gift Card and/ or carrier and can be checked at www.gifttodine.com.au. Cards may not be redeemed after their expiry date.</li>
                      <li className="mb-2">The Gift To Dine Gift Card cannot be used in conjunction with any other promotional offer unless otherwise advised by the individual participating restaurant and you acknowledge and agree that the terms and conditions of any participating restaurant’s promotional offer will apply.</li>
                      <li className="mb-2">There is no GST included in the cost of the Gift To Dine Gift Card. The GST is on the product that purchasers buy with their Restaurant Digital Gift Card.</li>
                      <li className="mb-2">The Gift To Dine Gift Card is multi-usable. You may check your balance and expiry date at <a href="https://www.gifttodine.com.au">www.gifttodine.com.au</a>.</li>
                      <li className="mb-2">All Gift To Dine Gift Cards purchased at any store are activated when purchased at the point-of-sale. Cardholders are advised to write the month and year of purchase on the back of the Carrier if this is not done at the point-of-sale  . Gift To Dine eGift Card can be purchased at giftz.com.au and is delivered activated to the recipient's email address  </li>
                      <li className="mb-2">If you have a query or complaint about the Gift To Dine Gift Card, please raise it through any of our contact channels as set out at clause 13 of these Terms and Conditions. Except as required by law, we are not liable for the availability, quality or fitness for purpose of any goods or services purchased with the Gift To Dine Gift Card. Any dispute about goods or services purchased with the Gift To Dine Gift Card must be resolved with the participating restaurant directly. Except for rights which cannot be excluded at law, including the Consumer Guarantees under the Australia Consumer Law, any conditions or warranties implied or imposed by legislation or otherwise are excluded from these Terms and Conditions. </li>
                      <li className="mb-2">Our contact channels are at <a href="/contactus">www.gifttodone.com.au/contact-us</a>.</li>
                      <li className="mb-2">For security reasons, or where required by law, including where our systems suspect fraud or other illegal activity, we may restrict the number of transactions or amount of transactions you can make with the Gift To Dine Gift Card, prevent a transaction or stop use of the Gift To Dine Gift Card. If we do so, we will provide a replacement Gift To Dine Gift Card of equivalent value unless we reasonably suspect fraud in relation to a Gift To Dine Gift Card. At all times, the Gift To Dine Gift Card remains our property.</li>
                      <li className="mb-2">Subject to this clause, we reserve the right to change these Terms and Conditions provided that the changes do not materially reduce or limit your rights under these Terms and Conditions. We may change these Terms and Conditions in a way that materially reduces or limits your rights under these Terms and Conditions only if we are required to do so by law, where such changes are imposed on us by third party suppliers or where the change is required to protect our legitimate commercial interests. Any changes to the Terms and Conditions will be notified at <a href="https://www.gifttodine.com.au">www.gifttodine.com.au</a>. If we make changes to these Terms and Conditions in a way that materially reduces or limits your rights under these Terms and Conditions, we will endeavour to give you 30 days’ notice by publishing the revised Terms and Conditions at <a href="https://www.gifttodine.com.au">www.gifttodine.com.au</a>. The current version of these Terms and Conditions is available through our website at <a href="https://www.gifttodine.com.au">www.gifttodine.com.au</a>. </li>
                      <li className="mb-2">If you provide personal information to Blackhawk, you consent to Blackhawk disclosing your personal information to third parties that: (a) facilitate transaction investigation and assist with identification of suspicious or fraudulent transactions; (b) are our outsourced service providers (for example, data switches); (c) are regulatory bodies, government agencies, law enforcement bodies and courts; and (d) are participants in the payment system and other financial institutions for the purpose of resolving disputes, errors or other matters arising from your use of your Gift To Dine Gift Card. Information will be disclosed to third parties about the Gift To Dine Gift Card, or transactions made with the Gift To Dine Gift Card, if required and allowed by law and also where necessary to operate the Gift To Dine Gift Card and process transactions. Blackhawk will collect and deal with personal information in accordance with its privacy policy which can be found at: <a href="https://blackhawknetwork.com/au-en/privacy-policy">https://blackhawknetwork.com/au-en/privacy-policy</a>.</li>
                      <li className="mb-2">These Terms and Conditions are governed by the laws of New South Wales. You unconditionally submit to the jurisdiction of the courts of New South Wales.</li>
                      <li className="mb-2">Blackhawk Network (Australia) Pty Ltd of Level 17, 101 Miller Street North Sydney NSW 2060 is the Card Issuer.</li>
                    </ol>
                    <hr />
                    <p className="mt-4 fw-bold"><strong>IMPORTANT</strong></p>
                    <p className="fw-bold fs-sm">EXPIRY DATE: CARDS EXPIRE AT THE END OF THE MONTH WHICH IS THREE (3) YEARS AFTER THE MONTH OF ACTIVATION. CARDS MAY NOT BE REDEEMED AFTER THEIR EXPIRY</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  )
}

export default TermsAndConditions;