import useAuthContext from "../hooks/useAuthContext.hook";

const useAuthHeader = () => {
  const validatedCard = useAuthContext();

  if (Object.keys(validatedCard).length && validatedCard.token.length) {
    return { Authorization: 'Bearer ' + validatedCard.token };
  }

}

export { useAuthHeader };