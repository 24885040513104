import { Swiper, SwiperSlide } from 'swiper/react';
import { useFetchData } from '../../hooks/useFetchData.hook';
import { useContext, useEffect, useState } from 'react';
import useAuthContext from '../../hooks/useAuthContext.hook';
import EmbedMap from '../../components/layout/Map';
import RestaurantSwapSection from '../../components/layout/restaurants/RestaurantSwapSection';
import RestaurantSwapLoginSection from '../../components/layout/restaurants/RestaurantSwapLoginSection';
import Loader from '../../components/layout/Loader';
import { NavLink, useLocation } from 'react-router-dom';
import { IncentiveContext } from '../../contexts/incentive.context';
import IncentiveContextData from '../../types/IncentiveContextData';

const RestaurantDetails = () => {
    const validatedCard = useAuthContext();
    const { state } = useLocation();
    const [isExpanded, setIsExpanded] = useState(false);
    const { setRestaurantData } = useContext(IncentiveContext);

    const {data, loading, fetchData} = useFetchData({
        method: "GET",
        url: "/api/SwapIncentives/Details/" + state?.id
    });

    const swiper = {
        slidesPerView: 1,
        loop: true,
        autoplay: true,
        pagination: {
            "el": ".swiper-pagination",
            "clickable": true
        },
        navigation: {
            "prevEl": "#prev-news",
            "nextEl": "#next-news"
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (data) {
            let incentiveData: IncentiveContextData = {
                incentiveId: data.incentiveId,
                incentiveName: data.incentiveName,
                logo: data.defaultImage!,
                suburbRegion: data.surburbRegion,
                termsConditions: data.termsConditions
            }
            setRestaurantData(incentiveData);
        }
    }, [data]);

    const expandDescription = (e: React.MouseEvent, flag: boolean) => {
        e.preventDefault();
        setIsExpanded(flag);
        return false;
    }

    const getCleanDescriptionContent = () => {
        return data.description?.replace(/<(.|\n)*?>/g, '').replace(/&amp;/g, '&');
    }

    return (
        <>
            <section id="restaurants" className="container py-5">
                {loading || !data ? <Loader /> :
                    <>
                        {/* <!-- Breadcrumbs --> */}
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><NavLink to="/restaurants">Browse</NavLink></li>
                                <li className="breadcrumb-item active" aria-current="page">{data.incentiveName.replace(/&amp;/g, '&')}</li>
                            </ol>
                        </nav>
                        {/* <!-- Restaurant Name and Logo --> */}
                        <div className="row d-flex align-items-center border-bottom pb-4 mb-4">
                            <div className="col">
                                <h1 className="mb-2 mb-md-0">{data.incentiveName.replace(/&amp;/g, '&')}</h1>
                            </div>
                            <div className="col-3 text-end">
                                <img src={data.logo} alt={`${data.incentiveName.replace(/&amp;/g, '&')} logo`} className="img-fluid border rounded" width="100" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-sm-7 pe-sm-5">
                                {/* <!-- Restaurant About --> */}
                                <h4 className="">About this restaurant</h4>
                                <div className="row">
                                    <div className="col-12 col-lg-8 ">
                                        <p className="">{isExpanded ? getCleanDescriptionContent() : getCleanDescriptionContent().length > 550 ? `${getCleanDescriptionContent().substring(0, 550)}` : getCleanDescriptionContent()}
                                            {(!isExpanded && getCleanDescriptionContent().length > 550) ? <a href="#" onClick={(e) => expandDescription(e, true)}>&nbsp;more...</a> : getCleanDescriptionContent().length > 550 && <a href="#" onClick={(e) => expandDescription(e, false)}>&nbsp;...less</a>}</p>
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <div className="row">
                                            <div className="col-6 col-sm-12">
                                                <p className="mb-0 fs-sm"><strong>Address</strong></p>
                                                <p className="fs-sm">{data.fullAddress}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="py-2 py-sm-4" />
                                {/* <!-- Restaurant Swap --> */}
                                {validatedCard && validatedCard.isAuthenticated ? <RestaurantSwapSection denominationList={data.denominationList!} /> : <RestaurantSwapLoginSection />}
                            </div>
                            <div className="col col-sm-5">
                                {/* <!-- Restaurant Images --> */}
                                <div className="position-relative rounded-2 shadow-lg overflow-hidden mt-3 mt-sm-0">
                                    {/* <!-- Slider prev/next buttons --> */}
                                    <button type="button" id="prev-news" className="btn btn-prev btn-icon btn-sm position-absolute top-50 start-0 translate-middle-y d-none d-xl-inline-flex ms-2" style={{ zIndex: '10', left: '10px!important' }}>
                                        <i className="bx bx-chevron-left"></i>
                                    </button>
                                    <button type="button" id="next-news" className="btn btn-next btn-icon btn-sm position-absolute top-50 end-0 translate-middle-y d-none d-xl-inline-flex ms-2" style={{ zIndex: '10', left: '10px!important' }}>
                                        <i className="bx bx-chevron-right"></i>
                                    </button>

                                    {/* <!-- Slider --> */}
                                    <div id="restaurant-gallery">
                                        <Swiper {...swiper}>
                                            <div className="swiper-wrapper" >
                                                {/* <!-- Gallery Image --> */}
                                                {data.imageList!.map((image: string, index: any) => {
                                                    return <SwiperSlide key={index}>
                                                        {<img src={image} alt={`${data.incentiveName.replace(/&amp;/g, '&')} image ${index}`} />}
                                                    </SwiperSlide>
                                                })}
                                            </div>

                                            {/* <!-- Pagination (bullets) --> */}
                                            <div className="swiper-pagination"></div>
                                        </Swiper>
                                    </div>
                                </div>
                                {/* <!-- Restaurant Map --> */}
                                <div className="row">
                                    <div className="col">
                                        <div className="rounded-2 overflow-hidden shadow-lg mt-4">
                                            <style>{
                                                `.embed-container {position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } 
                                        .embed-container iframe, .embed-container object, .embed-container embed {position: absolute; top: 0; left: 0; width: 100%; height: 100%; }`
                                            }
                                            </style>
                                            <div className='embed-container'>
                                                <EmbedMap address={data.incentiveName.replace(/&amp;/g, '') + ", " + data.fullAddress!}></EmbedMap>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </>
                }
            </section>
        </>
    )
}

export default RestaurantDetails;