
export default function CardSettings() {
    return (
        <>
            {/* <!--Card Settings-- > */}
            <div className="col-md-8 offset-lg-1 pb-5 mb-lg-4 pt-md-5 mt-n3 mt-md-0" >
                <form className="ps-md-3 ps-lg-0 mt-md-2 pt-md-4 pb-md-2" >
                    <div className="d-flex align-items-center justify-content-between pt-xl-1 mb-3 pb-3" >
                        <h1 className="h2 mb-0" > Card Settings </h1>
                        <button type="button" className="btn btn-outline-primary px-3 px-sm-4" >
                            <i className="bx bx-mobile-alt fs-xl me-sm-1" > </i>
                            <span className="d-none d-sm-inline" > Add to digital wallet </span>
                        </button>
                    </div>
                    <div className="row align-items-end pb-3 mb-2 mb-sm-4" >
                        <div className="col-lg-6 col-sm-7">
                            <label htmlFor="notifications-email" className="form-label fs-base" > Notification email </label>
                            <select id="notifications-email" className="form-select form-select-lg" >
                                <option value="jonny@email.com" > jonny@email.com</option>
                                <option value="john_doe@email.com" > john_doe@email.com</option>
                            </select>
                        </div>
                        <div className="col-lg-6 col-sm-5" >
                            <button type="button" className="btn btn-link btn-lg px-0" >
                                <i className="bx bx-plus fs-xl me-2" > </i>
                                Add new email
                            </button>
                        </div>
                    </div>
                    <div className="form-check form-switch form-switch-success d-flex mb-4" >
                        <input type="checkbox" id="activity" className="form-check-input flex-shrink-0" checked />
                        <label htmlFor="activity" className="form-check-label ps-3" >
                            <span className="h6 d-block mb-2" > Card active </span>
                            <span className="fs-sm text-muted" > Nec, posuere non felis duis massa vitae aliquet interdum scelerisque.Neque ullamcorper.</span>
                        </label>
                    </div>
                    <div className="form-check form-switch form-switch-success d-flex mb-4">
                        <input type="checkbox" id="account" className="form-check-input flex-shrink-0" checked />
                        <label htmlFor="account" className="form-check-label ps-3" >
                            <span className="h6 d-block mb-2" > Account activity </span>
                            <span className="fs-sm text-muted" > Maecenas aenean porta sit integer a duis commodo massa pulvinar.</span>
                        </label>
                    </div>
                    <div className="form-check form-switch form-switch-success d-flex mb-4" >
                        <input type="checkbox" id="promotions" className="form-check-input flex-shrink-0" />
                        <label htmlFor="promotions" className="form-check-label ps-3" >
                            <span className="h6 d-block mb-2" > Promotions, recommendations & amp; helpful resources </span>
                            <span className="fs-sm text-muted" > Aliquam sagittis fames pharetra placerat tempus.Volutpat orci neque elit malesuada mauris neque, tempus.</span>
                        </label>
                    </div>
                    <div className="d-flex pt-3 mb-3" >
                        <button type="reset" className="btn btn-secondary me-3" > Restore </button>
                        <button type="submit" className="btn btn-primary" > Save changes </button>
                    </div>
                </form>
            </div>
        </>
    )
}