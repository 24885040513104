import { Outlet } from "react-router-dom"
import Header from "./Header";
import Footer from "./Footer";
const PageLayout = () => {
    return (<>
        <main className="page-wrapper">
            <Header />
            <Outlet />
        </main >
        <Footer />
    </>
    )
}

export default PageLayout;