import { useContext, useEffect, useState } from "react";
import { NavLink, Link, Outlet, useNavigate } from "react-router-dom";
import useAuthContext from "../../hooks/useAuthContext.hook";
//import Cookies from "universal-cookie";
import { useFetchData } from "../../hooks/useFetchData.hook";
import toast from "react-hot-toast";
import { useAuthHeader } from "../../services/auth-header";
import { AuthContext } from "../../contexts/auth.context";
import { ValidatedCardData } from "../../types/ValidatedCardData";
export default function Card() {
    const validatedCard = useAuthContext();
    const navigate = useNavigate();
    //const cookie = new Cookies();
    const { setValidatedCardInfo } = useContext(AuthContext);
    const [currentBalance, setCurrentBalance] = useState(0);

    const {data, error, fetchData} = useFetchData({
        method: "POST",
        url: "/api/account/logout",
        headers: useAuthHeader()
    });

    const {data: dataBalance, error: errorBalance, fetchData: fetchDataBalance} = useFetchData({
        method: "GET",
        url: "/api/Card/Balance",
        headers: useAuthHeader()
    });
    useEffect(() => {
        fetchDataBalance();
    }, [])

    useEffect(() => {
        if (data) {
            toast.success("Logged out successfully");
        }

        if (error) {
            let errorMsg = "An error occurred while logging out.";

            if (typeof error.responseMessage !== "undefined")
                errorMsg += ":" + error.responseMessage;

            toast.error(errorMsg);
        }
    }, [data, error]);

    useEffect(() => {
        if (dataBalance) {
            setCurrentBalance(dataBalance.currentBalance);
        }

        if (errorBalance) {
            let errorMsg = "Failed to get the current balance: ";

            if (typeof errorBalance.responseMessage === "undefined")
                errorMsg += ":" + error.responseMessage;

            toast.error(errorMsg);
        }
    }, [dataBalance, errorBalance]);

    const signOut = (e: React.MouseEvent) => {
        e.preventDefault();
        fetchData();
        //cookie.remove('userInfo');
        localStorage.removeItem('swapInfo');
        localStorage.removeItem('userInfo');
        setValidatedCardInfo({} as ValidatedCardData);
        navigate('/')
    }

    const onItemClick = function (e: React.MouseEvent) {
        const activeElement = document.getElementById('account-menu')?.querySelectorAll('.active')[0];
        activeElement?.classList.remove('active');
        (e.currentTarget as HTMLElement)?.classList.add('active');
    }

    return (
        <>
            <section className="container pt-5">
                <div className="row">
                    {/* Sidebar (User info + Account menu) */}
                    <aside className="col-lg-3 col-md-4 border-end pb-5 mt-n5">
                        <div className="position-sticky top-0">
                            <div className="text-center pt-5">
                                <div className="d-table position-relative mx-auto mt-2 mt-lg-4 pt-5 mb-3">
                                    <img src="/img/card-demo.jpg" className="d-block rounded shadow" width="160" alt="Sample Card" />
                                </div>
                                <h2 className="h4 mb-1">Balance: ${currentBalance}</h2>
                                <p className="mb-0">{validatedCard.maskedCardNumberV1}</p>
                                <p className="mb-3 pb-3">Original Balance: ${validatedCard.originalBalance}</p>
                                <div className="position-sticky sticky-top">
                                    <button type="button" className="btn btn-secondary w-100 d-md-none mt-n2 mb-3" data-bs-toggle="collapse" data-bs-target="#account-menu">
                                        <i className="bx bx-menu fs-xl me-2"></i>
                                        Card Menu
                                        <i className="bx bx-chevron-down fs-lg ms-1"></i>
                                    </button>
                                    <div id="account-menu" className="list-group list-group-flush collapse d-md-block">
                                    <NavLink to="/restaurants" onClick={onItemClick} className="list-group-item list-group-item-action d-flex align-items-center">
                                            <i className="bx bx-refresh fs-xl opacity-60 me-2"></i>
                                            Swap
                                        </NavLink>
                                        <NavLink to="/card" onClick={onItemClick} className="list-group-item list-group-item-action d-flex align-items-center active">
                                            <i className="bx bx-collection fs-xl opacity-60 me-2"></i>
                                            History
                                        </NavLink>
                                        <Link to="/contactUs" onClick={onItemClick} className="list-group-item list-group-item-action d-flex align-items-center">
                                            <i className="bx bx-chat fs-xl opacity-60 me-2"></i>
                                            Support
                                        </Link>
                                        <a href="#" onClick={signOut} className="list-group-item list-group-item-action d-flex align-items-center">
                                            <i className="bx bx-log-out fs-xl opacity-60 me-2"></i>
                                            Sign out of card
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aside>
                    <Outlet />
                </div>
            </section>
        </>
    )
}