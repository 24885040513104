import { ReactNode, createContext, useState } from "react";
import { ValidatedCardData } from "../types/ValidatedCardData";

interface Props {
    children?: ReactNode
}

// interface AuthContextType {
//     validatedCard: ValidatedCardData,
//     signIn: (cardData: IUserCardData, callback: VoidFunction) => void;
//     signOut: (callBack: VoidFunction) => void;
// }

const AuthContext = createContext({ validatedCard: {} as ValidatedCardData, setValidatedCardInfo: (validatedCard: ValidatedCardData) => { } });

// const initialValue: ValidatedCardData = {
//     maskedCardNumber: "",
//     expiryDate: "",
//     token: "",
//     originalBalance: "",
//     currentBalance: ""
// }

const AuthProvider = ({ children }: Props) => {
    const [validatedCard, setValidatedCardInfo] = useState({} as ValidatedCardData);//initialValue
    const value = { validatedCard, setValidatedCardInfo }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider };