import { Link, NavLink, useLocation } from "react-router-dom"

const RestaurantSwapLoginSection = () => {
    const location = useLocation();
    return (
        <>
            <div className="row p-3 p-sm-0">
                <div className="col py-4 px-sm-3 py-sm-3 bg-dark dark-mode text-light rounded-2 shadow-lg">
                    <div className="d-lg-flex align-items-center">
                        <div className="px-lg-4">
                            <h4 className="text-primary"><i className="bx bx-refresh"></i> Swap Restaurant Gift Card</h4>
                            <p className="fs-lg mb-4 text-white">Enter your Gift To Dine Gift Card number to swap for this restaurant.</p>
                            <p>
                                <NavLink to="/validate" state={{ previousLocation: location }} className="d-block d-lg-inline btn btn-lg btn-secondary me-lg-3 mb-3 mb-lg-0"><i className="bx bx-refresh me-2"></i>Swap</NavLink>
                                <a href="https://giftz.com.au/pages/gift-to-dine" target="_blank" className="d-block d-lg-inline btn btn-outline-light btn-lg" rel="noreferrer">
                                    <i className="bx bx-credit-card fs-5 lh-1 me-1"></i>&nbsp;Purchase Gift Card
                                </a>
                            </p>
                            <p className="d-sm-none text-center"><Link to="/home#howitworks" className="text-white">How it works</Link></p>
                        </div>
                        <div className="d-none d-sm-block text-center">
                            <img src="/img/gtd-card.png" alt="Gift To Dine Sample card" className="img-fluid" style={{ maxWidth: '200px' }} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RestaurantSwapLoginSection;