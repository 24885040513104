export default function CardFavourites() {
    return (
        <>
            {/* <!-- Favourite Restaurants --> */}
            <div className="col-md-8 offset-lg-1 pb-5 mb-lg-2 pt-md-5 mt-n3 mt-md-0">
                <div className="ps-md-3 ps-lg-0 mt-md-2 pt-md-4 pb-md-2">
                    <div className="d-flex align-items-center justify-content-between pt-xl-1 mb-3 pb-3">
                        <h1 className="h2 mb-0">Favourite Restaurants</h1>
                        <button type="button" className="btn btn-outline-danger px-3 px-sm-4">
                            <i className="bx bx-trash-alt fs-xl me-sm-2"></i>
                            <span className="d-none d-sm-inline">Remove all</span>
                        </button>
                    </div>
                    <div className="row row-cols-1 row-cols-sm-2 gx-3 gx-lg-4">

                        {/* <!-- Item --> */}
                        {/* <div className="col pb-lg-2 mb-4">
                            <article className="card h-100 border-0 shadow-sm">
                                <div className="position-relative">
                                    <a href="portfolio-single-course.html" className="d-block position-absolute w-100 h-100 top-0 start-0"></a>
                                    <a href="#" className="btn btn-icon btn-light bg-primary border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-2 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left" aria-label="Remove" data-bs-original-title="Remove">
                                        <i className="bx bxs-bookmark text-white"></i>
                                    </a>
                                    <img src="/img/cards/China_Diner_eGift_Card.png" className="card-img-top" alt="Image" />
                                </div>
                                <div className="card-body pb-3">
                                    <h3 className="h5 mb-2">
                                        <a href="portfolio-single-course.html">China Diner</a>
                                    </h3>
                                    <p className="fs-sm mb-2">Sydney, NSW</p>
                                </div>
                                <div className="card-footer d-flex align-items-center fs-sm text-muted py-4">
                                    <div className="d-flex align-items-center me-4">
                                        <i className="bx bx-time fs-xl me-1"></i>
                                        Open Today
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <i className="bx bx-like fs-xl me-1"></i>
                                        94% (4.2K)
                                    </div>
                                </div>
                            </article>
                        </div> */}

                        {/* <!-- Item --> */}
                        {/* <div className="col pb-lg-2 mb-4">
                            <article className="card h-100 border-0 shadow-sm">
                                <div className="position-relative">
                                    <a href="portfolio-single-course.html" className="d-block position-absolute w-100 h-100 top-0 start-0"></a>
                                    <a href="#" className="btn btn-icon btn-light bg-primary border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-2 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left" aria-label="Remove" data-bs-original-title="Remove">
                                        <i className="bx bxs-bookmark text-white"></i>
                                    </a>
                                    <img src="/img/cards/Taxi_Kitchen_eGift_Card.png" className="card-img-top" alt="Image" />
                                </div>
                                <div className="card-body pb-3">
                                    <h3 className="h5 mb-2">
                                        <a href="portfolio-single-course.html">Taxi Kitchen</a>
                                    </h3>
                                    <p className="fs-sm mb-2">Sydney, NSW</p>
                                </div>
                                <div className="card-footer d-flex align-items-center fs-sm text-muted py-4">
                                    <div className="d-flex align-items-center me-4">
                                        <i className="bx bx-time fs-xl me-1"></i>
                                        Open Today
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <i className="bx bx-like fs-xl me-1"></i>
                                        94% (4.2K)
                                    </div>
                                </div>
                            </article>
                        </div> */}

                        {/* <!-- Item --> */}
                        {/* <div className="col pb-lg-2 mb-4">
                            <article className="card h-100 border-0 shadow-sm">
                                <div className="position-relative">
                                    <a href="portfolio-single-course.html" className="d-block position-absolute w-100 h-100 top-0 start-0"></a>
                                    <a href="#" className="btn btn-icon btn-light bg-primary border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-2 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left" aria-label="Remove" data-bs-original-title="Remove">
                                        <i className="bx bxs-bookmark text-white"></i>
                                    </a>
                                    <img src="/img/cards/White_and_Wongs_eGift_Card.png" className="card-img-top" alt="Image" />
                                </div>
                                <div className="card-body pb-3">
                                    <h3 className="h5 mb-2">
                                        <a href="portfolio-single-course.html">White and Wongs</a>
                                    </h3>
                                    <p className="fs-sm mb-2">Melbourne, VIC</p>
                                </div>
                                <div className="card-footer d-flex align-items-center fs-sm text-muted py-4">
                                    <div className="d-flex align-items-center me-4">
                                        <i className="bx bx-time fs-xl me-1"></i>
                                        Open Today
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <i className="bx bx-like fs-xl me-1"></i>
                                        94% (4.2K)
                                    </div>
                                </div>
                            </article>
                        </div> */}

                        {/* <!-- Item --> */}
                        {/* <div className="col pb-lg-2 mb-4">
                            <article className="card h-100 border-0 shadow-sm">
                                <div className="position-relative">
                                    <a href="portfolio-single-course.html" className="d-block position-absolute w-100 h-100 top-0 start-0"></a>
                                    <a href="#" className="btn btn-icon btn-light bg-primary border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-2 me-3 mt-3" data-bs-toggle="tooltip" data-bs-placement="left" aria-label="Remove" data-bs-original-title="Remove">
                                        <i className="bx bxs-bookmark text-white"></i>
                                    </a>
                                    <img src="/img/cards/Glen_Ewin_Estate_eGift_Card.png" className="card-img-top" alt="Image" />
                                </div>
                                <div className="card-body pb-3">
                                    <h3 className="h5 mb-2">
                                        <a href="portfolio-single-course.html">Glen Ewin Estate</a>
                                    </h3>
                                    <p className="fs-sm mb-2">Hunter Valley, NSW</p>
                                </div>
                                <div className="card-footer d-flex align-items-center fs-sm text-muted py-4">
                                    <div className="d-flex align-items-center me-4">
                                        <i className="bx bx-time fs-xl me-1"></i>
                                        Open Today
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <i className="bx bx-like fs-xl me-1"></i>
                                        94% (4.2K)
                                    </div>
                                </div>
                            </article>
                        </div> */}

                    </div>
                </div>
            </div>
        </>
    )
}