import { NavLink, Link, useLocation, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import toast from "react-hot-toast";
import useAuthContext from '../../hooks/useAuthContext.hook';
import { useFetchData } from '../../hooks/useFetchData.hook';
import { useAuthHeader } from '../../services/auth-header';
import { AuthContext } from '../../contexts/auth.context';
import { ValidatedCardData } from '../../types/ValidatedCardData';
import { Offcanvas } from 'bootstrap';

export default function Header() {
  const validatedCard = useAuthContext();
  const root = document.getElementsByTagName('html')[0];
  const [currentBalance, setCurrentBalance] = useState(0);
  const location = useLocation();
  const { setValidatedCardInfo } = useContext(AuthContext);
  const navigate = useNavigate();
  const { pathname, hash, key } = useLocation();
  const [sticky, setSticky] = useState("");

  useEffect(() => {
    const mode = localStorage.getItem('mode');
    if (mode === null) {
      window.localStorage.setItem('mode', 'light');
    }
  }, []);

  const onThemeChange = () => {
    const mode = localStorage.getItem('mode');
    if (mode !== null && mode === 'light') {
      localStorage.setItem('mode', 'dark');
      root.classList.add('dark-mode');
    }
    else {
      localStorage.setItem('mode', 'light');
      root.classList.remove('dark-mode');
    }
  }
  //logout begins
  const { data, error, fetchData } = useFetchData({
    method: "POST",
    url: "/api/account/logout",
    headers: useAuthHeader()
  });

  useEffect(() => {
    if (data) {
      toast.success("Logged out successfully");
    }

    if (error) {
      let errorMsg = "An error occurred while logging out.";

      if (typeof error.responseMessage !== "undefined")
        errorMsg += ":" + error.responseMessage;
      
      toast.error(errorMsg);
    }
  }, [data, error]);

  const signOut = (e: React.MouseEvent) => {
    e.preventDefault();
    fetchData();
    //cookie.remove('userInfo');
    localStorage.removeItem('swapInfo');
    localStorage.removeItem('userInfo');
    setValidatedCardInfo({} as ValidatedCardData);
    navigate('/')
  }

  //logout ends

  //card balance
  const { data: data1, error: error1, fetchData: fetchData1 } = useFetchData({
    method: "GET",
    url: "/api/Card/Balance",
    headers: useAuthHeader()
  });

  useEffect(() => {
    if (Object.keys(validatedCard).length) {
      fetchData1();
    }
  }, [validatedCard])

  useEffect(() => {
    if (data1) {
      setCurrentBalance(data1.currentBalance);
    }
  }, [data1, error1]);

  useEffect(() => {
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 500);
  }, [pathname, hash, key]);

  const closeNavbar = () => {
    let el = document.getElementById('navbarNav')!;
    let t = Offcanvas.getOrCreateInstance(el);
    t.hide();
  }

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 500 ? "navbar-stuck" : "";
    setSticky(stickyClass);
  };

  return (
    <header className={`header navbar navbar-sticky bg-dark ${sticky} `}>
      <div className="container px-3">
        <NavLink to='/' className="navbar-brand" aria-label='Navigate to Home page.'>
          <img id="gtd-logo" src="/img/Gift_To_Dine_Logo.png" width="200" alt="Gift To Dine Logo" height="25"/>
        </NavLink>
        <ul className="nav ms-auto me-3 d-none d-md-flex align-items-center">
          <li className="nav-item">
            <NavLink to="/restaurants" className="nav-link px-sm-4 text-light"><i className="bx bx-restaurant"></i>&nbsp;Restaurants</NavLink>
          </li>
          {!validatedCard.isAuthenticated &&
            <li className="nav-item me-3">
              <a href="https://giftz.com.au/pages/gift-to-dine" target="_blank" className="btn btn-primary btn-sm fs-sm rounded" rel="noreferrer">
                <i className="bx bx-credit-card fs-5 lh-1 me-1"></i>
                &nbsp;Purchase
              </a>
            </li>
          }
          <li className="nav-item">
            {(validatedCard.isAuthenticated && validatedCard.maskedCardNumber.length) ?
              <>
                <button type="button" className="btn btn-primary btn-sm fs-sm rounded dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  <i className="bx bx-credit-card fs-5 lh-1 me-1"></i>&nbsp;&bull;&bull;&bull;&bull;&nbsp;{validatedCard.maskedCardNumber}
                </button>
                <div className="dropdown-menu dropdown-menu-end my-1" aria-labelledby="dropdownMenuButton1">
                  <h5 className="dropdown-item mb-0">Balance: ${currentBalance}</h5>
                  <NavLink to="/card" className="dropdown-item">Card History</NavLink>
                  <NavLink to="/contactUs" className="dropdown-item">Support</NavLink>
                  <div className="dropdown-divider"></div>
                  <a href="#" onClick={signOut} className="dropdown-item d-flex align-items-center"><i className="bx bx-log-out fs-5 me-1"></i> <span>Sign out of card</span></a>
                </div>
              </>

              :
              <NavLink to="/validate" state={{ previousLocation: location }} className="btn btn-primary btn-sm fs-sm rounded" >
                <i className="bx bx-refresh fs-5 lh-1 me-1"></i>
                &nbsp;Swap
              </NavLink>
            }
          </li>
        </ul>
        <button type="button" className="btn btn-outline-primary btn-sm fs-sm rounded d-inline-flex ms-auto ms-md-0" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" style={{ height: '40px', paddingLeft: '0.8rem', paddingRight: '0.8rem' }}>
          <i className="bx bx-menu lh-1 fs-5"></i>
        </button>
        <div id="navbarNav" className="offcanvas offcanvas-end" >
          <div className="offcanvas-header border-bottom">
            <h5 className="offcanvas-title">Menu</h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          {(validatedCard.isAuthenticated && validatedCard.maskedCardNumber.length) ?
            <div className="offcanvas-header border-bottom bg-primary d-md-none">
              <div className="list-group w-100 bg-white">
                <a href="#" className="list-group-item list-group-item-action active d-flex align-items-center">
                  <i className="bx bx-credit-card fs-5 lh-1 me-1"></i>&nbsp;&bull;&bull;&bull;&bull;&nbsp;{validatedCard.maskedCardNumber}
                </a>
                <NavLink to="/restaurants" onClick={closeNavbar} className="list-group-item list-group-item-action">Swap</NavLink>
                <NavLink to="/card" onClick={closeNavbar} className="list-group-item list-group-item-action">Card History</NavLink>
                <NavLink to="/contactUs" onClick={closeNavbar} className="list-group-item list-group-item-action">Support</NavLink>
                <a href="#" onClick={signOut} className="list-group-item list-group-item-action">Sign out of card</a>
              </div>
            </div>
            : <div></div>
          }
          <div className="offcanvas-header border-bottom d-md-none">
            <ul className="navbar-nav w-100">
              <li className="nav-item" data-bs-dismiss="offcanvas">
                <a href="https://giftz.com.au/pages/gift-to-dine" target="_blank" className="nav-link" rel="noreferrer" >
                  <i className="bx bx-credit-card fs-5 lh-1 me-1"></i>
                  &nbsp;Purchase
                </a>
              </li>
              <li className="nav-item" data-bs-dismiss="offcanvas">
                <NavLink to="/"  className="nav-link" >
                  <i className="bx bx-refresh fs-5 lh-1 me-1"></i>
                  &nbsp;Swap
                </NavLink>
              </li>
              <li className="nav-item" data-bs-dismiss="offcanvas">
                <NavLink to="restaurants" className="nav-link" >
                  <i className="bx bx-restaurant fs-5 lh-1 me-1"></i>
                  &nbsp;View Restaurants
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav">
              <li className="nav-item" data-bs-dismiss="offcanvas" ><Link to="/#howitworks" className="nav-link">How it works</Link></li>
              {/* <li className="nav-item"><NavLink to="/about" className="nav-link">About</NavLink></li> */}
              <li className="nav-item" data-bs-dismiss="offcanvas"><NavLink to="/faq" className="nav-link">FAQ</NavLink></li>
              <li className="nav-item" data-bs-dismiss="offcanvas"><NavLink to="/contactUs" className="nav-link">Contact</NavLink></li>
              <li className="nav-item dropdown">
                <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Useful Links</a>
                <div className="dropdown-menu">
                  <div className="d-lg-flex">
                    <div className="mega-dropdown-column">
                      <ul className="list-unstyled">
                        <li data-bs-dismiss="offcanvas"><NavLink to="/tnc" className="dropdown-item py-1">Terms & Conditions</NavLink></li>
                        <li data-bs-dismiss="offcanvas"><a href="https://blackhawknetwork.com/au-en/privacy-policy" target="_blank" className="dropdown-item py-1" rel="noreferrer">Privacy Policy</a></li>
                        <li data-bs-dismiss="offcanvas"><a href="https://blackhawknetwork.com/au-en/terms-use" target="_blank" className="dropdown-item py-1" rel="noreferrer">Terms of Use</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="offcanvas-header border-top d-none d-sm-block">
            <div className="d-block w-100 text-center">
              <h6>Subscribe to our newsletter</h6>
              <div className="klaviyo-form-VqCRZY text-center"></div>
            </div>
          </div>

          <div className="offcanvas-header border-top">
            <ul className="navbar-nav w-100">
              <li className="nav-item">
                <div className="d-flex justify-content-between">
                  <div><small>Settings</small></div>
                  <div className="text-end">
                    <div className="form-check form-switch mode-switch ms-auto" data-bs-toggle="mode">
                      <input type="checkbox" onClick={onThemeChange} className="form-check-input" id="theme-mode" />
                      <label className="form-check-label d-none d-sm-block" htmlFor="theme-mode"><small>Light</small></label>
                      <label className="form-check-label d-none d-sm-block" htmlFor="theme-mode"><small>Dark</small></label>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header >
  );
}