import { useEffect, useState } from "react";
import { useFetchData } from "../hooks/useFetchData.hook";
import { ContactUsData } from "../types/ContactUsData";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import * as Yup from 'yup';
import { Formik, Form, FormikProps, ErrorMessage } from "formik";

//make this async
const ContactUs = () => {
    var elem = '<script src=https://chat.sharpen.cx appKey="56EB5B3C60938DEDA9133310EC353D183F38B7518F50D3E7CFB03C01846C184588E303483A80D68A"></script>';
    // useEffect(() => {
    //     var docElem = new DOMParser().parseFromString(elem, "text/html");
    //     var scriptElem = docElem.head.children[0];

    //     var appendElem = document.querySelector('body');

    //     appendElem?.insertAdjacentElement("afterend", scriptElem);

    //     return () => {
    //         document.body.removeChild(scriptElem);
    //     }
    // }, [elem]);

    const { data, fetchData } = useFetchData({
        method: "POST",
        url: "/api/ContactUs"
    });

    const regex = new RegExp('^\\b\\d{16}\\b|(\\d{4}\\s+\\d{4}\\s+\\d{4}\\s+\\d{4})$', 'gm');//^\\d{16}|(\\d{4}\\s+\\d{4}\\s+\\d{4}\\s+\\d{4})

    const validationSchema = Yup.object().shape({
        fullName: Yup.string().required("Full Name is required."),
        emailAddress: Yup.string().required("Email Address is required.")
            .email('Email Address format not correct'),
        subject: Yup.string().required("Subject is required."),
        cardNumber: Yup.string().required("16 digit Card Number is required.")
            .matches(regex, 'Invalid pattern. Card Number should be of 16 digits.'),
        message: Yup.string().required("Message is required."),
        mobile: Yup.string().required("Mobile Number is required.").matches(/^[0]\d{9}$/, "Please enter valid Mobile number.")
    })

    const navigate = useNavigate();

    //contact form submit
    const submitContactForm = (values: ContactUsData) => {
        const contactData: ContactUsData = {
            fullName: values.fullName,
            emailAddress: values.emailAddress,
            message: values.message,
            subject: values.subject,
            cardNumber: values.cardNumber,
            mobile: values.mobile
        };
        fetchData(contactData);

        resetForm(contactData);
    }

    const resetForm = (values: ContactUsData) => {
        values.fullName = '';
        values.subject = '';
        values.message = '';
        values.emailAddress = '';
        values.cardNumber = '';
        values.mobile = '';
    }

    useEffect(() => {
        if (data && data.responseCode === 0) {
            toast.success("Your Query is successfully submitted.");
            navigate('/');
        }
    })

    return (
        <>
            {/* <!-- Links + Contact form --> */}
            <section className="position-relative bg-secondary pt-5">
                <div className="container position-relative zindex-2 pt-5">

                    <div className="row">

                        {/* <!-- Contact links --> */}
                        <div className="col-xl-4 col-lg-5 pb-4 pb-sm-5 mb-2 mb-sm-0">
                            <div className="pe-lg-4 pe-xl-0">
                                <h1 className="pb-3 pb-md-4 mb-lg-5">Contact Us</h1>
                                <div className="d-flex align-items-start pb-3 mb-sm-1 mb-md-3">
                                    <div className="bg-light text-primary rounded-circle flex-shrink-0 fs-3 lh-1 p-3">
                                        <i className="bx bx-envelope"></i>
                                    </div>
                                    <div className="ps-3 ps-sm-4">
                                        <h2 className="h4 pb-1 mb-2">Email us</h2>
                                        <p className="mb-2">Please feel free to submit an enquiry. We will respond within 2 business days. </p>
                                        <div className="btn btn-link btn-lg px-0">
                                            Leave a message
                                            <i className="bx bx-right-arrow-alt lead ms-2"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start">
                                    <div className="bg-light text-primary rounded-circle flex-shrink-0 fs-3 lh-1 p-3">
                                        <i className="bx bx-group"></i>
                                    </div>
                                    <div className="ps-3 ps-sm-4">
                                        <h2 className="h4 pb-1 mb-2">Call centre</h2>
                                        <p className="mb-2">If you'd prefer to speak to us please contact us on 1800 297 069.<br /><br />
                                            <strong>Support hours:</strong><br />
                                            Monday-Sunday<br />
                                            9am-10pm AEST </p>
                                        <div className="btn btn-link btn-lg px-0">
                                            Call us
                                            <i className="bx bx-right-arrow-alt lead ms-2"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-xl-6 col-lg-7 offset-xl-2">
                            <div className="card border-light shadow-lg py-3 p-sm-4 p-md-5">
                                <div className="bg-dark position-absolute top-0 start-0 w-100 h-100 rounded-3 d-none d-dark-mode-block"></div>
                                <div className="card-body position-relative zindex-2">
                                    {/* <!-- Contact form --> */}
                                    <Formik initialValues={{ fullName: "", emailAddress: "", subject: "", message: "", mobile: "", cardNumber: "" }} validationSchema={validationSchema} onSubmit={submitContactForm} validateOnChange={true} validateOnBlur={false}>
                                        {(props: FormikProps<ContactUsData>) => {
                                            const {
                                                values,
                                                touched,
                                                errors,
                                                handleChange,
                                                isSubmitting,
                                            } = props
                                            return (
                                                <Form id="contactForm">
                                                    <h2 className="card-title pb-3 mb-4">Send us a message</h2>

                                                    <div className="col-12">
                                                        <label htmlFor="fullName" className="form-label fs-base">Full name</label>
                                                        <input type="text" className="form-control form-control-lg" name="fullName" id="fullName" value={values.fullName} onChange={handleChange} autoComplete="off" />
                                                        {errors.fullName && touched.fullName && <ErrorMessage name="fullName" component="div" className="text-danger" />}
                                                    </div>
                                                    <div className="col-12">
                                                        <label htmlFor="emailAddress" className="form-label fs-base">Email address</label>
                                                        <input type="email" className="form-control form-control-lg" id="emailAddress" value={values.emailAddress} onChange={handleChange} autoComplete="off" />
                                                        {errors.emailAddress && touched.emailAddress && <ErrorMessage name="emailAddress" component="div" className="text-danger" />}
                                                    </div>
                                                    <div className="col-12">
                                                        <label htmlFor="mobile" className="form-label fs-base">Mobile #</label>
                                                        <input type="text" className="form-control form-control-lg" id="mobile" value={values.mobile} onChange={handleChange} autoComplete="off" />
                                                        {errors.mobile && touched.mobile && <ErrorMessage name="mobile" component="div" className="text-danger" />}
                                                    </div>
                                                    <div className="col-12">
                                                        <label htmlFor="cardNumber" className="form-label fs-base">Card Number</label>
                                                        <input type="text" className="form-control form-control-lg" id="cardNumber" value={values.cardNumber} onChange={handleChange} autoComplete="off" />
                                                        {errors.cardNumber && touched.cardNumber && <ErrorMessage name="cardNumber" component="div" className="text-danger" />}
                                                    </div>
                                                    <div className="col-12">
                                                        <label htmlFor="subject" className="form-label fs-base">Subject</label>
                                                        <select className="form-select form-select-lg" id="subject" value={values.subject} onChange={handleChange} autoComplete="off" >
                                                            <option value="">Choose a subject</option>
                                                            <option value="Not able to Validate">Gift A Dine - Not able to Validate</option>
                                                            <option value="Not able to Swap">Gift A Dine - Not able to Swap</option>
                                                        </select>
                                                        {errors.subject && touched.subject && <ErrorMessage name="subject" component="div" className="text-danger" />}
                                                    </div>
                                                    <div className="col-12">
                                                        <label htmlFor="message" className="form-label fs-base">Message</label>
                                                        <textarea name="message" className="form-control" id="message" cols={30} rows={5} value={values.message} onChange={handleChange} autoComplete="off"></textarea>
                                                        {errors.message && touched.message && <ErrorMessage name="message" component="div" className="text-danger" />}
                                                    </div>
                                                    <div className="col-12 pt-2 pt-sm-3">
                                                        <button disabled={isSubmitting} className="btn btn-lg btn-primary w-100 w-sm-auto" type="submit">Send</button>
                                                    </div>
                                                </Form>)
                                        }}
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col pt-5">
                            <p className="fs-sm">Gifttodine.com.au is operated by Blackhawk Network Holdings, Inc. the world’s leading gift card company.
                                <br /><br />
                                Blackhawk Network Holdings, Inc. (NASDAQ: HAWK and HAWKB) is a leading Global prepaid and payments network, which supports the program management and the physical and digital distribution of a large range of prepaid products. Founded in 2001, Blackhawk Network utilises proprietary technology to provide consumers a wide selection of gift cards. Through Blackhawk's digital platform, the company supports prepaid products across a growing network of digital distribution partners including leading retailers, financial service providers, social apps, mobile wallets and other integrated physical-to-digital channels. Founded in 2001, Blackhawk Network is an innovative fintech company with global reach with more than 3,000 employees around the world and operations in 28 Countries. Blackhawk's APAC headquarters are based in Sydney, Australia.
                                <br /><br />
                                For more information please visit, <a href="https://blackhawknetwork.com/au-en">https://blackhawknetwork.com/au-en</a></p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUs;