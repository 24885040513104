import useIncentiveContext from "../../hooks/useIncentiveContext.hook"
import { NavLink } from 'react-router-dom'

const RestaurantTnC = () => {
    const restaurantData = useIncentiveContext();
    return (
        <>
            <section className="bg-faded-primary">
                <div className="container">
                    <section className="container pt-1 pt-lg-3">
                        <div className="position-relative overflow-hidden px-3 px-sm-4 px-md-0 py-5">


                            <div className="row justify-content-center position-relative zindex-2 py-lg-4">
                                <div className="col-xl-8 col-lg-9 col-md-10 py-2">
                                    {Object.keys(restaurantData).length ?
                                        <>
                                            <h2 className="h1 text-center mt-n2 mt-lg-0 mb-4 mb-lg-5">Terms &amp; Conditions</h2>
                                            <div className="card border-0 rounded-3 shadow-sm p-4">

                                                <p>Redeeming customers within the venue will be required to produce proof of identification at the time of redeeming the Gift Card. If the redeeming customer is unable to provide identification the venue may elect to not accept the Gift Card as payment until the redeeming customer is able to do so. <strong>{restaurantData.incentiveName}</strong> Gift Cards are non-refundable. <strong>{restaurantData.incentiveName}</strong> Gift Cards are treated as cash and cannot be replaced if lost or stolen. <strong>{restaurantData.incentiveName}</strong> Gift Cards expire a certain number of months after issue with the expiry date clearly documented on the Gift Card. <strong>{restaurantData.incentiveName}</strong> Gift Cards may only be used for purchases at the specifically listed venue/s documented on the Gift Card and cannot be returned or exchanged for cash. Redeeming customers can redeem up to $1000 per transaction in the Venue.  <strong>{restaurantData.incentiveName}</strong> Gift Cards may not be re-sold or bartered for cash. The specified venue does reserve the right to change any of the terms contained in these Terms and Conditions at any time where the change is required. By purchasing, redeeming or attempting to redeem a <strong>{restaurantData.incentiveName}</strong> Gift Card, you: accept and agree to be bound by these Terms and Conditions; and acknowledge that you have read and understood The Gift A Restaurant Pty Ltd Privacy Policy as the approved distributor of this <strong>{restaurantData.incentiveName}</strong> gift card. Visit the venues website for the venues full Terms and Conditions.</p>
                                            </div>
                                        </>
                                        : <div className="card border-0 rounded-3 shadow-sm p-4"><NavLink to="/tnc">Terms &amp; Conditions</NavLink></div>
                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </section>
        </>
    )
}

export default RestaurantTnC;