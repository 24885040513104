import { useNavigate } from "react-router-dom";
import ISwappedCardData from "../../../types/RestaurantCardData";

const HistoryCard = (card: ISwappedCardData) => {
    const viewCardUrl = () => {
        window.open(card.swapCardUrl, "_blank");
    }
    const navigate = useNavigate();

    return (<>
        {/* Item  */}
        <div className="card border-0 shadow-sm overflow-hidden mb-4">
            {card.swapCardUrl === null && <span className="badge bg-info">Gift Card on the way... <i className="bx bx-question-mark text-dark bg-white rounded-circle" data-bs-toggle="tooltip" title="Your Gift Card is being generated by our system. It will be in your inbox soon"></i></span>}
            <div className="row g-0">
                <div className="col-sm-4 p-4">
                    <img src={card.logo} className="img-fluid rounded border" alt={`${card.incentiveName.replace(/&amp;/g, '&')} logo`}/>
                </div>
                <div className="col-sm-8">
                    <div className="card-body">
                        <div className="fs-sm text-muted mb-1">{card.orderedDate}</div>
                        <h2 className="h4 pb-1 mb-2">
                            <a href="#">{card.incentiveName.replace(/&amp;/g, '&')}</a>
                        </h2>
                        <p className="mb-4 mb-lg-5">${card.cardAmount}<br />{card.recipientEmail}</p>
                        <div className="d-flex">
                            {card.swapCardUrl !== null && <button type="button" onClick={viewCardUrl} className="btn btn-primary px-3 px-lg-4 me-3">
                                <i className="bx bx-credit-card fs-xl me-2"></i>
                                <span className="d-xl-inline">Download Gift Card</span>
                            </button>}
                            <button type="button" className="btn btn-outline-secondary px-3 px-lg-4 ms-auto" onClick={() => navigate('/restaurant-details', { state: { id: card.incentiveId } })}>
                                {/* setShowDetailsModal(true) */}
                                <i className="bx bx-restaurant fs-xl me-2"></i>
                                <span className="d-inline">Details</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* {showDetailsModal && <RestaurantDetailsModal show={showDetailsModal} incentiveId={Number(card.incentiveId)} updateShowModalFlag={(flag) => { setShowDetailsModal(flag) }}></RestaurantDetailsModal>} */}
    </>
    )
}

export default HistoryCard;