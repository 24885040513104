import { ReactNode, createContext, useState } from "react";
import IncentiveContextData from "../types/IncentiveContextData";

interface Props {
    children?: ReactNode
}


const IncentiveContext = createContext({ restaurantData: {} as IncentiveContextData, setRestaurantData: (restaurantData: IncentiveContextData) => { } });

const IncentiveContextProvider = ({ children }: Props) => {
    const [restaurantData, setRestaurantData] = useState({} as IncentiveContextData);
    const value = { restaurantData, setRestaurantData };
    
    return (
        <IncentiveContext.Provider value={value}>
            {children}
        </IncentiveContext.Provider>
    )
}

export { IncentiveContext, IncentiveContextProvider };