import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import Denomination from "../../../types/Denomination"
import { IncentiveContext } from "../../../contexts/incentive.context";
import React, { useContext, useEffect, useState } from "react";
import { useFetchData } from "../../../hooks/useFetchData.hook";
import { useAuthHeader } from "../../../services/auth-header";
import useIncentiveContext from "../../../hooks/useIncentiveContext.hook";
import { useNavigate } from "react-router-dom";

const RestaurantSwapSection = (props: { denominationList: Denomination[] }) => {
    const { setRestaurantData } = useContext(IncentiveContext);
    const restaurantData = useIncentiveContext();
    const navigate = useNavigate();
    const [denomination, setDenomination] = useState({} as Denomination);
    const [isRedeemButtonEnabled, setIsRedeemButtonEnabled] = useState(false);
    const [currentBalance, setCurrentBalance] = useState(0);

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Please provide an email address.'),
        confirmEmail: Yup.string().required('Please confirm your email address.')
            .test('match', 'Email Addresses do not match', function (emailConfirmation) {
                return emailConfirmation === this.parent.email;
            })
    });

    const submitSwapDetails = (values: { email: string, confirmEmail: string }) => {
        setRestaurantData({ ...restaurantData, emailAddress: values.email, denomination: denomination });
        localStorage.removeItem('swapInfo');
        localStorage.setItem('swapInfo', JSON.stringify(restaurantData));
        navigate('/swap-confirm');
    }

    const {data: dataBalance, error: error1, fetchData: fetchDataBalance} = useFetchData({
        method: "GET",
        url: "/api/Card/Balance",
        headers: useAuthHeader()
    });
    useEffect(() => {
        fetchDataBalance();
    }, []);

    useEffect(() => {
        if (dataBalance) {
            setCurrentBalance(dataBalance.currentBalance.replace(/[^0-9.-]+/g, ""));
        }

    }, [dataBalance, error1]);


    return (
        <>
            <div className="row p-3 p-sm-0">
                <div className="col py-4 px-sm-3 py-sm-3 bg-dark text-light rounded-2 shadow-lg">
                    <h4 className="text-primary"><i className="bx bx-refresh"></i> Swap Restaurant Gift Card</h4>
                    <Formik initialValues={{ email: '', confirmEmail: '' }} validationSchema={validationSchema} onSubmit={submitSwapDetails}>
                        {({
                            values,
                            errors,
                            isSubmitting,
                            touched
                        }) => (
                            <Form>
                                <div className="form-group mb-3">
                                    <label htmlFor="select-input" className="form-label text-light">Select Gift Card Value:</label>
                                    <select defaultValue="" className="form-select" id="select-input" name="denomination" onChange={
                                        (e: React.ChangeEvent<HTMLSelectElement>) => {
                                            setDenomination({
                                                id: e.target.value, value: e.currentTarget[e.target.selectedIndex].getAttribute('label')!
                                            });
                                            if (Number(currentBalance) > 0)
                                                setIsRedeemButtonEnabled(true);
                                            else
                                                setIsRedeemButtonEnabled(false);
                                        }}>
                                        <option value="" disabled >Choose option...</option>
                                        {props.denominationList.map((option, index) => { return <option key={index} value={option.id} label={"$" + option.value}></option> })}
                                    </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label htmlFor="recepient-email" className="form-label text-light">Send Gift Card To:</label>
                                    <Field id="email" type="email" className="form-control" placeholder="Gift Card Recipient Email Address" value={values.email} />
                                    {errors.email && touched.email && <ErrorMessage name="email" component="div" className="text-danger" />}
                                </div>
                                <div className="form-group mb-3" >
                                    <Field id="confirmEmail" type="email" value={values.confirmEmail} className="form-control" placeholder="Re-enter email address" onPaste={(e: React.KeyboardEvent | React.MouseEvent) => e.preventDefault()} autoComplete="off"></Field>
                                    {errors.confirmEmail && touched.confirmEmail && <ErrorMessage name="confirmEmail" component="div" className="text-danger" />}
                                </div>
                                <button disabled={isSubmitting || !isRedeemButtonEnabled} className="btn btn-secondary">Redeem Gift Card</button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div >
        </>
    )
}

export default RestaurantSwapSection;