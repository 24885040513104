import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IncentiveContext } from "../../contexts/incentive.context";
import IncentiveContextData from "../../types/IncentiveContextData";

const SwapProcessComplete = () => {
    const { restaurantData, setRestaurantData } = useContext(IncentiveContext);
    const navigate = useNavigate();

    const downloadCard = (e: React.MouseEvent) => {
        e.preventDefault();
        clearIncentiveContext();
        window.open(restaurantData.swapResponse.swapCardUrl, "_blank");
        navigate('/restaurants');
    }

    const clearIncentiveContext = () => {
        setRestaurantData({} as IncentiveContextData);
        localStorage.removeItem('swapInfo');
    }

    return (
        <>
            <section id="restaurants" className="container py-5">
                {/* <!-- Breadcrumbs --> */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><NavLink to="/restaurants">Browse</NavLink></li>
                        <li className="breadcrumb-item"><NavLink to="/restaurant-details" state={{ id: restaurantData.incentiveId }}>{restaurantData.incentiveName.replace(/&amp;/g, '&')}</NavLink></li>
                        <li className="breadcrumb-item"><NavLink to="/restaurant-details" state={{ id: restaurantData.incentiveId }}>Confirm</NavLink></li>
                        <li className="breadcrumb-item active" aria-current="page">Order Details</li>
                    </ol>
                </nav>
                {/* <!-- Restaurant Name and Logo --> */}
                {restaurantData.swapResponse &&
                    <>
                        <div className="row d-flex align-items-center">
                            <div className="col">
                                {restaurantData.swapResponse.responseCode === 0 && <h1 className="mb-2">Gift Card Swapped!</h1>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {restaurantData.swapResponse.swapCardUrl ?
                                    <>
                                        <p className="">Your {restaurantData.denomination?.value} {restaurantData.incentiveName.replace(/&amp;/g, '&')} Digital Gift Card will be sent to <strong>{restaurantData.emailAddress}</strong></p>
                                        <a href="#" onClick={downloadCard} className="d-block d-sm-inline btn btn-primary me-sm-2 mb-3 mb-sm-0" ><i className="bx bxs-file-pdf me-2"></i> Download PDF</a>
                                    </>
                                    :
                                    <p className="">{restaurantData.swapResponse.responseMessage}</p>
                                }
                                <NavLink to="/card" onClick={clearIncentiveContext} className="d-block d-sm-inline btn btn-outline-dark me-sm-2 mb-3 mb-sm-0">Card History</NavLink>
                                <NavLink to="/restaurants" onClick={clearIncentiveContext} className="d-block d-sm-inline btn btn-outline-dark me-2">Swap another Gift Card</NavLink>
                            </div>
                        </div>
                    </>
                }
            </section>
        </>
    )
}

export default SwapProcessComplete;