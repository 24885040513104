const Pagination = (props: { currentPage: number, totalPages: number, onPageChange: (pageNumber: number) => void }) => {
    const pageNumbers = [];

    //console.log(props.totalPages);
    //console.log(props.currentPage);
    if (props.totalPages > 3) {
        if (props.totalPages === props.currentPage) {
            const pages = props.currentPage;
            const startPage = props.currentPage - 2 > 1 ? props.currentPage - 2 : 1;

            for (let i = startPage; i <= pages; i++) {
                pageNumbers.push(i);
            }
        }
        else {
            const pages = props.currentPage < props.totalPages ? props.currentPage + 1 : 1;
            const startPage = props.currentPage > 1 ? props.currentPage - 1 : 1;

            for (let i = startPage; i <= pages; i++) {
                pageNumbers.push(i);
            }
        }
    } else {
        for (let i = 1; i <= props.totalPages; i++) {
            pageNumbers.push(i);
        }
    }
    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center pt-lg-3 pt-1" style={{ paddingBottom: '1rem' }}>
                {(props.currentPage - 1 >= 1) ?
                    <li className="page-item">
                        <button onClick={() => props.onPageChange(props.currentPage - 1)} className="page-link" aria-label="Previous"><i className="bx bx-chevron-left mx-n1"></i></button>
                    </li>
                    : ''}
                {
                    pageNumbers.map((pageNumber) => (
                        <li className={`page-item d-sm-block ${props.currentPage === pageNumber ? " active" : ""}`} key={pageNumber}>
                            <button onClick={() => props.onPageChange(pageNumber)} className="page-link" disabled={props.currentPage === pageNumber}>{pageNumber}</button>
                        </li>
                    ))

                }
                {(props.currentPage <= props.totalPages - 1) ?
                    <li className="page-item">
                        <button onClick={() => props.onPageChange(props.currentPage + 1)} className="page-link" aria-label="Next"><i className="bx bx-chevron-right mx-n1"></i></button>
                    </li>
                    : ''}
            </ul>
        </nav>
    );

};

export default Pagination;