import { ReactNode, createContext, useState } from "react";
import IncentiveSearchContextData from "../types/IncentiveSearchContextData";

interface Props {
    children?: ReactNode
}


const IncentiveSearchContext = createContext({ searchParams: {} as IncentiveSearchContextData, setSearchParams: (searchParams: IncentiveSearchContextData) => { } });

const IncentiveSearchContextProvider = ({ children }: Props) => {
    const [searchParams, setSearchParams] = useState({} as IncentiveSearchContextData);
    const value = { searchParams, setSearchParams };
    
    return (
        <IncentiveSearchContext.Provider value={value}>
            {children}
        </IncentiveSearchContext.Provider>
    )
}

export { IncentiveSearchContext, IncentiveSearchContextProvider };