import React, { useContext, useEffect, useState } from "react"
import { NavLink, useNavigate } from "react-router-dom";
import { useFetchData } from "../../hooks/useFetchData.hook";
import { useAuthHeader } from "../../services/auth-header";
import { v4 as uuid } from 'uuid';
import { IncentiveContext } from "../../contexts/incentive.context";
import useAuthContext from "../../hooks/useAuthContext.hook";

const RestaurantSwapConfirm = () => {
    const { restaurantData, setRestaurantData } = useContext(IncentiveContext);
    const validatedCard = useAuthContext();
    const [isTnCRead, setIsTnCRead] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const {data, error, fetchData} = useFetchData({
        method: "POST",
        url: "/api/Card/Swap",
        headers: useAuthHeader()
    });

    const confirmSwap = () => {
        const requestData = {
            incentiveId: restaurantData.incentiveId,
            denominationId: restaurantData.denomination?.id,
            emailAddress: restaurantData.emailAddress,
            transactionReferenceNo: uuid()
        };
        setIsLoading(true);
        fetchData(requestData);
    }

    useEffect(() => {
        if (data) {
            setRestaurantData({ ...restaurantData, swapResponse: data });
            navigate('/swap-complete', { replace: true });
        }

        if (error) {
            setRestaurantData({ ...restaurantData, swapResponse: error.data });
            navigate('/swap-complete', { replace: true });
        }

    }, [data, error]);

    return (
        <>
            <section id="restaurants" className="container py-5">
                {/* <!-- Breadcrumbs --> */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><NavLink to="/restaurants">Browse</NavLink></li>
                        <li className="breadcrumb-item"><NavLink to="/restaurant-details" state={{ id: restaurantData.incentiveId }}>{restaurantData.incentiveName.replace(/&amp;/g, '&')}</NavLink></li>
                        <li className="breadcrumb-item active" aria-current="page">Confirm</li>
                    </ol>
                </nav>
                {/* <!-- Restaurant Name and Logo --> */}
                <div className="row d-flex align-items-center">
                    <div className="col">
                        <h1 className="mb-2">Great Choice!</h1>
                        <p className="">You are about to swap <strong>{restaurantData.denomination?.value} balance from Card {validatedCard.maskedCardNumber}</strong></p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <img src={restaurantData.logo} alt={`${restaurantData.incentiveName.replace(/&amp;/g, '&')} logo`} className="img-fluid d-block border rounded mb-3" width="200" />
                        <p className="mb-2"><strong>Restaurant Gift Card:</strong> {`${restaurantData.incentiveName.replace(/&amp;/g, '&')} ${restaurantData.suburbRegion} Digital Gift Card`}</p>
                        <p className="mb-2"><strong>Amount:</strong> {restaurantData.denomination?.value}</p>
                        <p><strong>Recipient Email:</strong> {restaurantData.emailAddress}</p>
                        <form action="">
                            <p className="">Please ensure you have read {restaurantData.incentiveName.replace(/&amp;/g, '&')} Digital Gift Card <NavLink to="/restaurant-tnc" target="_blank" rel="noreferrer">Terms & Conditions</NavLink> before continuing.</p>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="ex-check-1" disabled={isLoading} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setIsTnCRead(e.target.checked) }} />
                                <label className="form-check-label" htmlFor="ex-check-1"><p>I have read and agree to the Terms and Conditions</p></label>
                            </div>
                        </form>

                        <button type="submit" disabled={!isTnCRead || isLoading} onClick={confirmSwap} className="d-block d-sm-inline btn btn-primary me-2" >{isLoading ? <i className='bx bxs-circle bx-burst  fs-xl me-xl-2'></i> : 'Confirm Swap'}</button>
                        <button onClick={() => navigate(-1)} disabled={isLoading} className="d-block d-sm-inline btn btn-outline-dark mt-3 mt-sm-0">Go Back</button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RestaurantSwapConfirm;