import { NavLink } from "react-router-dom";

const Unauthorized = () => {
    return (<>
        <div style={{ height: 'auto', minHeight: '100%' }}>
            <div className="error-page" style={{ textAlign: 'center', width: '800px', marginLeft: '-400px', position: 'absolute', top: '30%', left: '50%' }}>
                <h1 style={{ margin: '0', fontSize: '150px', lineHeight: '150px', fontWeight: 'bold' }}>403</h1>
                <h2 style={{ marginTop: '20px', fontSize: '30px' }}>Access Denied
                </h2>
                <p style={{ fontSize: '15px' }}>Return to <NavLink to="/">homepage</NavLink>.</p>
            </div>
        </div>
    </>)
}

export default Unauthorized;