import { useEffect } from "react";
import React from "react";
import ISwappedCardData from "../../types/RestaurantCardData";
import { useFetchData } from "../../hooks/useFetchData.hook";
import HistoryCard from "../../components/layout/card/HistoryCard";
import Loader from "../../components/layout/Loader";
import { useAuthHeader } from "../../services/auth-header";

export default function CardHistory() {
    const initialValues: ISwappedCardData[] = [];
    const [swappedCards, setSwappedCards] = React.useState(initialValues);

    const {data, loading, fetchData} = useFetchData({
        method: "GET",
        url: "/api/Card/History",
        headers: useAuthHeader()
    });

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (data) {
            if (data.responseCode === 0) {
                setSwappedCards([...swappedCards, ...data.data])
            }
        }
    }, [data]);
    return (
        <>
            {loading && <Loader />}
            {/* Account collections */}
            <div className="col-md-8 offset-lg-1 pb-5 mb-lg-2 pt-md-5 mt-n3 mt-md-0">
                <div className="ps-md-3 ps-lg-0 mt-md-2 pt-md-4 pb-md-2">
                    <div className="d-sm-flex align-items-center justify-content-between pt-xl-1 mb-3 pb-3">
                        <h1 className="h2 mb-sm-0">Card History</h1>
                    </div>
                    {swappedCards.length ? swappedCards.map((card, index) => {
                        return <HistoryCard key={index} {...card}></HistoryCard>
                    }) : <h2 className="h5 mb-sm-0">No orders found.</h2>}

                </div>
            </div>
        </>
    );
}