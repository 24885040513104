import axios, { AxiosRequestConfig } from "axios";
import { useContext, useMemo, useState } from "react";
import { AxiosContext } from "../contexts/axios.context";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/auth.context";
import { ValidatedCardData } from "../types/ValidatedCardData";

const useFetchData = (params: AxiosRequestConfig<any>) => {
    const [data, setData] = useState<any>(null);
    const [error, setError] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    let toastId = 'card-validation';
    const { setValidatedCardInfo } = useContext(AuthContext);
    const contextInstance = useContext(AxiosContext);
    const instance = useMemo(() => {
        return contextInstance || axios;
    }, [contextInstance]);

    instance.defaults.withCredentials = true;

    instance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (error) => {
            const originalRequest = error.config;

            if (error.response.status === 401) {
                if (originalRequest.url === '/validate') {
                    return Promise.reject(error);
                } else if (error.response != null) {
                    localStorage.removeItem('userInfo');
                    return Promise.reject(error);
                }
            } else if (error.response.status === 400) {
                if (originalRequest.method === 'post') {
                    return Promise.reject(error);
                } else {
                    navigate('/bad-request');
                    return Promise.reject(error);
                }
            } else if (error.response.status === 418) {
                return Promise.reject(error);
            } else if (error.response.status === 404) {
                navigate('/not-found');
            } else if (error.response.status === 409) {
                return Promise.reject(error);
            } else if (error.response.status === 500) {
                navigate('/internal-server-error');
            } else if (error.response.status === 415) {
                navigate('/bad-request');
            } else if (error.response.status === 403) {
                const toastId = "toast-403";
                toast("Warning: Your account was logged in from another browser or device recently. If that activity was not initiated by you, please consider changing your password or contact system administrator immediately.", { id: toastId });
                localStorage.removeItem('userInfo');
                localStorage.removeItem('swapInfo');
                navigate('/access-denied');
            }
            else {
                return Promise.reject(error);
            }
        }
    )

    const fetchData = async (requestData?: any): Promise<void> => {
        try {
            params.data = requestData;
            const response = await instance.request(params);
            setData(response.data);
            setError(null);
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                if (error.response && error.response.status === 401) {
                    setValidatedCardInfo({} as ValidatedCardData);
                    toast.error("Session expired.", { id: toastId });
                    // navigate('/validate', { state: { previousLocation: location } });
                    navigate('/');
                }
                else {
                    toast.error(error.response?.data.responseMessage, { id: toastId });
                    setError(error.response);
                }
            } else {

                setError(error);
            }

            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    return {data, error, loading, fetchData} as const;
}

export { useFetchData };