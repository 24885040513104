import { NavLink } from "react-router-dom";

export default function Footer() {
    return (
        <footer className="footer pt-5 pb-4 pb-lg-5 bg-dark text-light">
            <div className="container pt-lg-4">
                <div className="row pb-5">
                    <div className="col-lg-4 col-md-6">
                        <div className="navbar-brand text-dark p-0 me-0 mb-3 mb-lg-4">
                            <img src="/img/Gift_To_Dine_Logo.png" alt="Gift To Dine" width="200" />
                        </div>
                        <p className="pb-lg-3 mb-4">With so many amazing restaurants to choose from, picking the perfect restaurant gift card for someone else can be tricky. The Gift To Dine gift card allows your special someone to choose where they want to dine. Gain access to hundreds of Restaurant gift cards with one card.</p>
                    </div>
                    <div className="col-xl-6 col-lg-7 col-md-5 offset-xl-2 offset-md-1 pt-4 pt-md-1 pt-lg-0">
                        <div id="footer-links" className="row">
                            <div className="col-lg-4">
                                <h5 className="d-none d-lg-block mb-2 text-light">Useful Links</h5>
                                <h6 className="mb-2">
                                    <a href="#useful-links" className="d-none d-lg-none mb-2 text-light"
                                        data-bs-toggle="collapse">Useful Links</a>
                                </h6>
                                <div id="useful-links" className="collapse d-lg-block" data-bs-parent="#footer-links">
                                    <ul className="nav flex-column pb-lg-1 mb-lg-3">
                                        <li className="nav-item"><NavLink to="faq" className="nav-link d-inline-block px-0 pt-1 pb-2 text-light">FAQs</NavLink></li>
                                        <li className="nav-item"><NavLink to="tnc" className="nav-link d-inline-block px-0 pt-1 pb-2 text-light">Terms & Conditions</NavLink>
                                        </li>
                                        <li className="nav-item"><a href="https://blackhawknetwork.com/au-en/privacy-policy" target="_blank" className="nav-link d-inline-block px-0 pt-1 pb-2 text-light" rel="noreferrer">Privacy Policy</a>
                                        </li>
                                        <li className="nav-item"><a href="https://blackhawknetwork.com/au-en/terms-use" target="_blank" className="nav-link d-inline-block px-0 pt-1 pb-2 text-light" rel="noreferrer">Terms of Use</a></li>
                                        <li className="nav-item"><a href="https://giftarestaurant.com/business-solutions-for-restaurants/" target="_blank" className="nav-link d-inline-block px-0 pt-1 pb-2 text-light" rel="noreferrer">Restaurateurs</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-5 pt-2 pt-lg-0">
                                <h5 className="mb-2 text-light">Contact Us</h5>
                                <ul className="nav flex-column mb-2 mb-lg-0">
                                    <li className="nav-item"><a href="/Contactus" className="nav-link d-inline-block px-0 pt-1 pb-2 text-light">Send a message</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                <p className="mb-0 lead text-primary"><strong>Subscribe to our newsletter</strong></p>
                <p className="mt-2 fs-xs text-muted">Please sign me up to receive communication, special offers from Blackhawk Network (owner of Gift To Dine) and its related corporate bodies.</p>
                <div className="klaviyo-form-VqCRZY"></div>
                </div>
                
                <p className="nav d-block fs-xs text-center text-md-start pb-2 pb-lg-0 mb-0">
                    &copy; All rights reserved. Made by &nbsp;
                    <a className="nav-link d-inline-block p-0 text-light" href="https://blackhawknetwork.com/au-en" target="_blank" rel="noopener noreferrer">Blackhawk Network</a>
                </p>
            </div>
        </footer>
    );
}