import RestaurantDetailsData from "../../../types/RestaurantDetails"
import { useNavigate } from "react-router-dom";
import 'boxicons/css/boxicons.css';
//import AsyncImage from "../../../helpers/imageLoader";

const RestaurantCard = (restaurant: RestaurantDetailsData) => {
    const navigate = useNavigate();
    const onClickDetails = (e: React.MouseEvent) => {
        e.preventDefault();
        // localStorage.setItem('searchInfo', JSON.stringify(searchParams));
        navigate('/restaurant-details', { state: { id: restaurant.incentiveId } });
    }

    const getCleanDescriptionContent = () => {
        return restaurant.description?.replace(/<(.|\n)*?>/g, '').replace(/&amp;/g, '&');
    }

    return (
        <>
            <div className="col col-sm-6 col-xl-4 mb-3 mb-sm-3 d-flex align-items-stretch">

                <div className="card" id={restaurant.incentiveId}>
                    <div className="position-relative">
                        <img src={restaurant.defaultImage} className="card-img-top" alt="Card" />
                        {/* <button className="btn btn-icon btn-light bg-white border-white btn-sm rounded-circle position-absolute top-0 end-0 zindex-5 me-3 mt-3" onClick={addToFavorites} data-bs-toggle="tooltip" data-bs-placement="left" aria-label="Add to Favourites" data-bs-original-title="Add to Favourites">
                            <i className="bx bx-heart"></i>
                        </button> */}
                    </div>
                    <div className="card-body">
                        <div className="d-flex align-items-start">
                            <div className="pe-3">
                                <h5 className="card-title">{restaurant.incentiveName.replace(/&amp;/g, '&')}</h5>
                                <p className="fs-sm mb-0"><i className="bx bx-map"></i>&nbsp;{restaurant.surburbRegion?.replace(/&amp;/g, '&')}</p>
                            </div>
                            <div className="d-block ms-auto">
                                <img src={restaurant.logo} className="border rounded card-img-bottom" alt={`${restaurant.incentiveName.replace(/&amp;/g, '&')} logo`} width="" />
                            </div>
                        </div>
                        <hr className="mt-3 pb-3" />
                        {restaurant.description?.length < 200 ? <p className="card-text fs-sm">{getCleanDescriptionContent()} </p> :
                            <p className="card-text fs-sm">{getCleanDescriptionContent().substring(0, 200)}<a href="#" onClick={onClickDetails}>&nbsp;more...</a></p>}
                        <a href="#" onClick={onClickDetails} className="btn btn-outline-dark" >More Details</a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RestaurantCard;