import React, { useContext, useEffect, useState } from "react";
import RestaurantCard from "../../components/layout/restaurants/RestaurantCard";
import RestaurantDetailsData from "../../types/RestaurantDetails";
import { useFetchData } from "../../hooks/useFetchData.hook";
import Loader from "../../components/layout/Loader";
import 'boxicons/css/boxicons.css';
import Pagination from "../../components/layout/Pagination";
import { IncentiveContext } from "../../contexts/incentive.context";
import IncentiveContextData from "../../types/IncentiveContextData";
import { IncentiveSearchContext } from "../../contexts/incentiveSearch.context";

export default function Restaurants() {
    const initialArray: RestaurantDetailsData[] = [];
    const [restaurants, setRestaurants] = React.useState(initialArray);
    const { setRestaurantData } = useContext(IncentiveContext);
    const { searchParams, setSearchParams } = useContext(IncentiveSearchContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPosts, setCurrentPosts] = useState(initialArray);
    const postsPerPage = 12;
    const { data, loading, fetchData } = useFetchData({
        method: "POST",
        url: "api/SwapIncentives/Search"
    });

    useEffect(() => {
        let restaurantName = ''
        let stateLocation = ''
        setRestaurantData({} as IncentiveContextData);
        localStorage.removeItem('swapInfo');
        if (Object.keys(searchParams).length) {
            restaurantName = searchParams.incentiveName;
            stateLocation = searchParams.stateLocation;
        }

        fetchData({ IncentiveName: restaurantName, State: stateLocation });
    }, []);

    useEffect(
        () => {
            if (data) {
                if (data.data.length > 0) {
                    setRestaurants([...restaurants, ...data.data]);
                    let pageCount = Math.ceil(data.data.length / postsPerPage);

                    setTotalPages(pageCount > 0 ? pageCount : 1);
                    setCurrentPage(Object.keys(searchParams).length ? searchParams.pageNumber : 1);
                } else {
                    //setRestaurantData([])
                    setRestaurants(initialArray => [])
                    setCurrentPosts([...restaurants, ...data.data]);
                    setCurrentPage(1)
                    setTotalPages(1);
                }
            }
        }, [data]
    )

    const handlePageChange = (pageNumber: number) => {
        setTimeout(() => {
            let el = document.getElementById('restaurants');
            if (el) {
                el.scrollIntoView({ behavior: "smooth" });
            }
        }, 500);

        setCurrentPage(pageNumber);

        let restaurantName = (document.getElementById('restaurantName') as HTMLInputElement).value;
        let stateLocation = (document.getElementById('stateLocation') as HTMLSelectElement).value;
        setSearchParams({ incentiveName: restaurantName, stateLocation: stateLocation, pageNumber: pageNumber });
        //setSearchParams({ ...searchParams, pageNumber: pageNumber });
    };

    useEffect(() => {
        if (restaurants.length) {
            const indexOfLastPost = currentPage * postsPerPage;
            const indexOfFirstPost = indexOfLastPost - postsPerPage;

            setCurrentPosts(restaurants.slice(indexOfFirstPost, indexOfLastPost));
        }
    }, [restaurants, currentPage]);

    const searchRestaurants = () => {
        let restaurantName = (document.getElementById('restaurantName') as HTMLInputElement).value;
        let stateLocation = (document.getElementById('stateLocation') as HTMLSelectElement).value;
        setSearchParams({ incentiveName: restaurantName, stateLocation: stateLocation, pageNumber: 1 });
        setRestaurants([]);
        fetchData({ IncentiveName: restaurantName, State: stateLocation });
    }

    const handleKeyUp = (e: React.KeyboardEvent) => {
        e.preventDefault();

        if (e.key === 'Enter') {
            searchRestaurants();
        }
    }

    const onLocationChange = () => {
        searchRestaurants();
    }

    return (
        <>
            <section id="restaurants" className="container py-5">
                {/* <!-- Breadcrumbs --> */}
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="#">Browse</a></li>
                    </ol>
                </nav>
                <h1 className="mb-2 mb-md-0">Search Restaurants</h1>
                <div className="row gy-2 mt-3 mb-4 align-items-center">
                    <div className="col-lg-5 col-md-4">
                        <div className="input-group">
                            <input type="text" id="restaurantName" className="form-control pe-5 rounded" defaultValue={searchParams.incentiveName} placeholder="Search for a restaurant..." onKeyUp={handleKeyUp} />
                            <i className="bx bx-search position-absolute top-50 end-0 translate-middle-y me-3 zindex-5 fs-lg"></i>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-8">
                        <div className="d-flex flex-wrap flex-md-nowrap align-items-center">
                            <select id="stateLocation" className="form-select me-md-3 mb-2 mb-md-0" defaultValue={searchParams.stateLocation?.length ? searchParams.stateLocation : "NSW"} onChange={onLocationChange} aria-label="State">
                                <option value="ACT">ACT</option>
                                <option defaultValue="NSW" selected>NSW</option>
                                <option value="QLD">QLD</option>
                                <option value="VIC">VIC</option>
                                <option value="SA">SA</option>
                                <option value="TAS">TAS</option>
                                <option value="WA">WA</option>
                            </select>
                            <select className="form-select" aria-label="Sort By">
                                <option>Sort By</option>
                                <option defaultValue="alpha" selected>Sort By:  A-Z</option>
                            </select>
                            <div className="d-block d-md-flex align-items-center ms-md-3 mt-3 mt-md-0">
                                <button className="btn btn-primary" onClick={searchRestaurants} ><i className="bx bx-search"></i>&nbsp;Search</button>
                            </div>
                        </div>
                    </div>

                </div>
                {loading ? <Loader /> :
                    <>
                        <div className="row">
                            <div className="col">
                                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                            </div>
                        </div>
                        <div className="row">
                            {currentPosts.map((res, index) => { return <RestaurantCard key={index} {...res}></RestaurantCard> })}
                        </div>
                        <div className="row">
                            <div className="col">
                                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                            </div>
                        </div>
                    </>
                }
            </section>

            {/* <!-- Back to top button --> */}
            <a href="#top" className="btn-scroll-top" data-scroll aria-label="Scroll to top">
                <span className="btn-scroll-top-tooltip text-muted fs-sm me-2">Top</span>
                <i className="btn-scroll-top-icon bx bx-chevron-up"></i>
            </a>
        </>
    )
}