import { Route, Routes, useLocation } from "react-router-dom";
import { lazy, Suspense } from 'react';
import './css/App.css';
import Restaurants from "./pages/incentives/Restaurants";
import Faq from "./pages/Faq";
import CardFavourites from "./pages/card/CardFavourites";
import CardSettings from "./pages/card/CardSettings";
import CardHistory from "./pages/card/CardHistory";
import { Toaster } from 'react-hot-toast';
import { AxiosInstanceProvider } from "./contexts/axios.context";
import InternalServerError from "./pages/error-pages/InternalServerError";
import BadRequest from "./pages/error-pages/BadRequest";
import NotFound from "./pages/error-pages/NotFound";
import AuthorizedRoutes from "./components/AuthorizedRoutes";
import ContactUs from "./pages/ContactUs";
import TermsAndConditions from "./pages/TermsAndConditions";
import PageLayout from "./components/layout/PageLayout";
import Loader from "./components/layout/Loader";
import RestaurantDetails from "./pages/incentives/RestaurantDetails";
import RestaurantSwapConfirm from "./pages/incentives/RestaurantSwapConfirm";
import SwapProcessComplete from "./pages/incentives/SwapProcessComplete";
import Card from "./pages/card/Card";
import ScrollToTop from "./components/layout/ScrollToTop";
import Unauthorized from "./pages/error-pages/Unauthorized";
import { IncentiveSearchContextProvider } from "./contexts/incentiveSearch.context";
import RestaurantTnC from "./pages/incentives/RestaurantTnC";

const Home = lazy(() => import('./pages/Home'));
const Swap = lazy(() => import('./pages/Swap'));

function App() {
  const location = useLocation();
  const previousLocation = location.state?.previousLocation;

  return (
    <>
      <AxiosInstanceProvider config={{ baseURL: process.env.REACT_APP_BASEURL, withCredentials: true }} >
        <>
          {/* responseInterceptors={[{ responseInterceptor, errorInterceptor }]} */}

          <ScrollToTop />
          <Suspense fallback={<Loader />}>
            <IncentiveSearchContextProvider>
              <Routes location={previousLocation || location}>
                <Route element={<PageLayout />}>
                  <Route path="/" element={<Home />}></Route>
                  <Route path="/faq" element={<Faq />}></Route>
                  <Route path="/restaurants" element={<Restaurants />}></Route>
                  <Route path="/contactUs" element={<ContactUs />}></Route>
                  <Route path="/tnc" element={<TermsAndConditions />} />
                  <Route path="/restaurant-details" element={<RestaurantDetails />}></Route>
                  <Route path="/restaurant-tnc" element={<RestaurantTnC />} />
                  <Route path="/swap-confirm" element={<AuthorizedRoutes />}>
                    <Route index element={<RestaurantSwapConfirm />} />
                  </Route>
                  <Route path="/swap-complete" element={<AuthorizedRoutes />}>
                    <Route index element={<SwapProcessComplete />} />
                  </Route>
                  <Route element={<Card />}>
                    <Route path="/card" element={<AuthorizedRoutes />}>
                      <Route index element={<CardHistory />}></Route>
                      <Route path="/cardfavourites" element={<CardFavourites />}></Route>
                      <Route path="/cardsettings" element={<CardSettings />}></Route>
                    </Route>
                  </Route>
                </Route>
                <Route path="/not-found" element={<NotFound />}></Route>
                <Route path="/bad-request" element={<BadRequest />}></Route>
                <Route path="/internal-server-error" element={<InternalServerError />}></Route>
                <Route path="/access-denied" element={<Unauthorized />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
              <Routes>
                {previousLocation && (
                  < Route path="/validate" element={<Swap />}></Route>
                )}
              </Routes>
            </IncentiveSearchContextProvider>
          </Suspense>
        </>
        <Toaster />
      </AxiosInstanceProvider>
    </>
  );
}

export default App;
