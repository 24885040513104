import { useContext, useMemo } from "react";
import { IncentiveContext } from "../contexts/incentive.context";
import IncentiveContextData from "../types/IncentiveContextData";

const useIncentiveContext = () => {
    const { restaurantData } = useContext(IncentiveContext);

    let swapInfo = localStorage.getItem('swapInfo');
    const instance = useMemo(() => {
        if (Object.keys(restaurantData).length > 0)
            return restaurantData;
        if (swapInfo)
            return JSON.parse(swapInfo) as IncentiveContextData;
        return {} as IncentiveContextData;

    }, [restaurantData]);

    return instance as IncentiveContextData;
}

export default useIncentiveContext;