import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuthContext from "../hooks/useAuthContext.hook";

const AuthorizedRoutes = () => { //props: { children: React.ReactNode }
    const  validatedCard = useAuthContext();
    const location = useLocation();

    if (!validatedCard.isAuthenticated) {
        return < Navigate to="/validate" state={{ previousLocation: location }} replace />
    }
    return <Outlet />
}

export default AuthorizedRoutes;